import axios from "axios";

export const getContents = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/contents`, {
    headers: {
      session: authtoken
    },
  });
};

export const getContentRecommened = async (authtoken) => {
    return await axios.get(`${process.env.REACT_APP_API}/contents/recommend`, {
      headers: {
        session: authtoken
      },
    });
  };

  export const getContent = async (authtoken, id) => {
    return await axios.get(`${process.env.REACT_APP_API}/content/id`, {
      headers: {
        session: authtoken
      },
      params: {
        id: id
      }
    });
  };
//-----------
  export const getContentPublic = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API}/content/id`, {
  
      params: {
        id: id
      }
    });
  };

  export const getCategory = async (authtoken) => {
    return await axios.get(`${process.env.REACT_APP_API}/categories`, {
      headers: {
        session: authtoken
      }
    });
  };

  export const getContentByMonth = async (authtoken, date) => {
    return await axios.get(`${process.env.REACT_APP_API}/content/date`, {
      headers: {
        session: authtoken
      },
      params: {
        date: date
      }
    });
  };

  export const getContentRelate = async (authtoken, id) => {
    return await axios.get(`${process.env.REACT_APP_API}/content/id/category`, {
      headers: {
        session: authtoken
      },
      params: {
        id: id
      }
    });
  };

  export const getContentRelatePublic = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API}/content/id/category`, {
      params: {
        id: id
      }
    });
  };

  export const test = async (authtoken, id) => {
    return await axios.get(`${process.env.REACT_APP_API}/content/filter`, {
      headers: {
        session: authtoken,
      },
      params: {
        id: id
      }
    });
  };

  export const getGallery = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API}/content/galleries`, {
      params: {
        id: id
      }
    });
  };