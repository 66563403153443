import RegisterLayout from "../layouts/RegisterLayout";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect } from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";

const LineVerifySuccess = () => {
  const liff = window.liff;
  const navigate = useNavigate();

  // const REACT_APP_LINE_LIFF = "1661291001-WNVDV3Qn";

  //UAT1660831164-gbndMPGJ
  const REACT_APP_LINE_LIFF = "1660831164-gbndMPGJ";

  useEffect(() => {
    liff.init({ liffId: REACT_APP_LINE_LIFF }).then(async () => {
        if (liff.isLoggedIn()) {
          const lineAccessToken = liff.getAccessToken();
          sessionStorage.setItem("lineAccessToken", lineAccessToken);
          // navigate("/login");
          // navigate("/auth-line");
          navigate("/login");
        } else {
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <RegisterLayout title="Verify">
      <div style={{ textAlign: "center" }} className="h-100">
        <Row justify="space-around" align="middle" className="h-100">
          <Col span={24}>
            <img
              src="../assets/images/page_point/logo.png"
              alt=""
              className="w-75"
            />
          </Col>
        </Row>
      </div>
    </RegisterLayout>
  );
};

export default LineVerifySuccess;
