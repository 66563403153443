import React, { useState, useEffect, useContext } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { NavLink, useNavigate } from "react-router-dom";
import RegisterLayout from "../layouts/RegisterLayout";
import { Col, Row, Modal } from "antd";
import { toast } from "react-toastify";
import { getQuestion, saveAnswer } from "../../functions/question";
import { UserContext } from "../../context";
import Banner from "../layouts/Banner";
import { getEditProfile_ } from "../../functions/adddress";

const { confirm } = Modal;

const Questions = () => {
  const [state, setState] = useContext(UserContext);
  // state

  const navigate = useNavigate();
  const [quiz, setQuiz] = useState("");
  const [quizId, setQuizId] = useState(0);
  const [choices, setChoices] = useState([]);
  const [choice, setChoice] = useState(0);
  const [isAnswer, setIsAnswer] = useState(false);
  const [member_id, setMember_id] = useState(0);

  const authtoken = localStorage.getItem("authtoken");

  const loadEditProfile = async () => {
    getEditProfile_(state.token).then((res) => {
      setMember_id(res.data.data.payloads.member_id);
    });
  };

  useEffect(() => {
    if (state && state.token) {
      loadEditProfile();
      getQuestion(state.token).then((res) => {
        if (res.status === 200) {
          const data = res.data;
          if (data.length > 0) {
            // console.log("data", data);
            setQuiz(data[0].title);
            setQuizId(data[0].id);
            setChoices(data[0].choices);
          } else {
            navigate("/home");
          }
        }
      });
    }
  }, [state && state.token]);

  const showConfirm = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "ส่งคำตอบ",
      okText: "ยืนยัน",
      centered: true,
      cancelText: "ยกเลิก",
      onOk() {
        saveAnswer(state.token, {
          quiz: quizId,
          choice: choice,
          member_id: member_id,
        }).then((res) => {
          if (res.status === 404) {
            toast.error("Something went wrong!");
          } else if (res.status === 200 || res.status === 201) {
            // navigate("/questions");
            window.location.reload();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handlerSelectCategory = (id) => {
    setChoice(id);
    setIsAnswer(true);
  };

  const ImgChoice = ({ id, title, img, isActive, onClick }) => {
    return (
      // <div className="col-6">
      <div style={{ marginBottom: "10px" }}>
        <NavLink key={id} to={""} onClick={() => handlerSelectCategory(id)}>
          <div className="img">
            <img
              style={{ width: "300px" }}
              src={img}
              alt={title}
              className={isActive ? "sky active" : "sky"}
            />
          </div>
        </NavLink>
      </div>
    );
  };

  return (
    <div
      className="bg-home"
      style={{
        backgroundImage: `url("../assets/images/page_sky/Page_01.png")`,
        overflow: "scroll",
      }}
    >
      <RegisterLayout title="Questions">
        <Banner />
        <div style={{ textAlign: "center" }} className="mt-3">
          <Row>
            <Col span={24}>
              <img
                src="../assets/images/page_point/logo.png"
                alt=""
                className="w-50"
              />
            </Col>
            <Col span={24}>
              <img
                src="../assets/images/page_sky/article-sky.png"
                alt=""
                className="w-75"
              />
            </Col>
            <Col span={24} style={{ marginTop: "10px" }}>
              <h3 style={{ color: "#215bde" }}>{quiz}</h3>
            </Col>
          </Row>
        </div>
        <div className="container">
          <div
            className="row wrapper-sky"
            // style={{ marginLeft: "14%", top: "unset" }}
            style={{ top: "unset", marginLeft: "8%" }}
          >
            {choices.map((c) => (
              <ImgChoice
                {...c}
                isActive={choice === c.id}
                onClick={handlerSelectCategory}
              />
            ))}
            {/* <div className="col-6">
            <NavLink to={""}
            onClick={() => handlerSelectCategory(1)}>
              <div className="img">
                <img
                  src="../assets/images/page_sky/img1.png"
                  alt=""
                  className="sky active"
                />
              </div>
            </NavLink>
          </div>
          <div className="col-6">
            <NavLink to={""}
            onClick={() => handlerSelectCategory(2)}>
              <div className="img">
                <img
                  src="../assets/images/page_sky/img2.png"
                  alt=""
                  className="sky active"
                />
              </div>
            </NavLink>
          </div>
          <div className="col-6">
            <NavLink to={""}>
              <div className="img">
                <img
                  src="../assets/images/page_sky/img3.png"
                  alt=""
                  className="sky"
                />
              </div>
            </NavLink>
          </div>
          <div className="col-6">
            <NavLink to={""}>
              <div className="img">
                <img
                  src="../assets/images/page_sky/img4.png"
                  alt=""
                  className="sky"
                />
              </div>
            </NavLink>
          </div>
          <div className="col-6">
            <NavLink to={""}>
              <div className="img">
                <img
                  src="../assets/images/page_sky/img5.png"
                  alt=""
                  className="sky"
                />
              </div>
            </NavLink>
          </div> */}
            {/* <div className="col-6"></div> */}

            <div className="col-12 mt-3 p-0 ">
              <div className="row">
                <div className="col-6">
                  {isAnswer === false && (
                    <button
                      disabled
                      className="bg-secondary py-2 rounded-pill border-0"
                      style={{ width: "150px" }}
                      onClick={showConfirm}
                    >
                      <img
                        src="../assets/images/page_sky/send.png"
                        alt=""
                        className="send ms-2"
                      />
                      <img
                        src="../assets/images/page_sky/next.png"
                        alt=""
                        className="next me-2"
                      />
                    </button>
                  )}

                  {isAnswer === true && (
                    <button
                      className="bg-primary  py-2 rounded-pill border-0"
                      style={{ width: "150px" }}
                      onClick={showConfirm}
                    >
                      <img
                        src="../assets/images/page_sky/send.png"
                        alt=""
                        className="send ms-2"
                      />
                      <img
                        src="../assets/images/page_sky/next.png"
                        alt=""
                        className="next me-2"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row wrapper-sky2 mx-auto"></div>
        </div>
      </RegisterLayout>
    </div>
  );
};

export default Questions;
