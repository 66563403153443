import axios from "axios";

export const checkQuestion = async (authtoken, id) => {
  return await axios.get(`${process.env.REACT_APP_API}/check`, {
    headers: {
      session: authtoken,
    },
    params: {
      id: id,
    },
  });
};

export const getQuestion = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/quiz`, {
    headers: {
      session: authtoken,
    },
  });
};

export const saveAnswer = async (authtoken, data) =>
  await axios.post(`${process.env.REACT_APP_API}/quiz/save`, data, {
    headers: {
      session: authtoken,
    },
  });

export const saveDemo = async (authtoken, data) =>
  await axios.post(`${process.env.REACT_APP_API}/quiz/demo`, data, {
    headers: {
      session: authtoken,
    },
  });
