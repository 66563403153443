import React, { useContext, useState, useEffect } from "react";
import BannerDetail from "../layouts/BannerDetail";
import { NavLink, useNavigate } from "react-router-dom";
import MenuMain from "./MenuMain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { MuiFbPhotoGrid } from "mui-fb-photo-grid";
import { getContent, getContentRelate } from "../../functions/content";
import { useParams } from "react-router-dom";
import RegisterLayout from "../layouts/RegisterLayout";
import { ShareAltOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import { Space, Button, Modal } from "antd";
import { UserContext } from "../../context";

const { confirm } = Modal;

const ContentDetail = () => {
  const [state, setState] = useContext(UserContext);
  // state
  const liff = window.liff;

  const navigate = useNavigate();
  const { innerHeight: height } = window;
  const authtoken = localStorage.getItem("authtoken");
  const accessToken = localStorage.getItem("accessToken");
  const [galleries, setGalleries] = useState([]);
  const [relate, setRelate] = useState([]);
  const [name, setName] = useState("");
  const [view, setView] = useState("");
  const [description, setDescription] = useState("");
  const { id } = useParams();
  const blankImg = "../assets/images/page_point/img.png";
  const [title_, setTitle] = useState("");

  useEffect(() => {
    if (state && state.token) {
      loadData(id);
      loadRelate(id);
    }
  }, [state && state.token]);

  const loadData = (id) => {
    getContent(state.token, id)
      .then((res) => {
        const data = res.data.data[0];
        // setContent(data[0])
        setTitle(data.title);
        setName(data.title);
        setView(data.view);
        setDescription(data.description);
        if (data.gallery !== "" && data.gallery !== "[]") {
          let galleryData = JSON.parse(data.gallery);
          setGalleries(reFormatGallery(galleryData));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadRelate = (id) => {
    getContentRelate(state.token, id)
      .then((res) => {
        const data = res.data.data.related;

        if (data.length > 0) {
          setRelate(reFormatRelate(data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reFormatGallery = (data) => {
    let gallery = data.map(function (obj) {
      obj["title"] = obj["public_id"];
      obj["img"] = obj["url"];
      obj["imgThumbnail"] = obj["url"]; // Assign new key
      delete obj["public_id"];
      delete obj["url"]; // Delete old key
      return obj;
    });
    return gallery;
  };

  const reFormatRelate = (data) => {
    let relate = data.map(function (obj) {
      obj["img"] = obj["img"] == "" ? blankImg : obj["img"];

      return obj;
    });
    return relate;
  };

  const gotoRelate = (id) => {
    setGalleries([]);
    loadData(id);
    loadRelate(id);
  };

  const downloadGallery = (e) => {
    e.preventDefault();

    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "ยืนยันการดาวน์โหลด Gallery",
      okText: "ยืนยัน",
      centered: true,
      cancelText: "ยกเลิก",
      onOk() {
        liff.openWindow({
          url: `${process.env.REACT_APP_URL}/thankyou/${id}`,
          external: true,
        });
      },
      onCancel() {},
    });
  };

  const shareGallery = (e) => {
    e.preventDefault();

    liff
      .shareTargetPicker(
        // [
        //   {

        //     type: "text",
        //     altText : `${name}`,
        //     text: `${process.env.REACT_APP_PUBLIC_CONTANT}/${id}`,

        //     // text: `${process.env.REACT_APP_URL_LINE_OFFICIAL}`,
        //   },
        // ],
        // {
        //   isMultiple: true,
        // }
        [
          {
            type: "flex",
            altText: `${name}`,
            contents: {
              type: "bubble",
              body: {
                type: "box",
                layout: "vertical",
                spacing: "md",
                contents: [
                  {
                    type: "text",
                    text: `${name}`,
                    size: "xl",
                    gravity: "center",
                    weight: "bold",
                    wrap: true,
                  },
                  {
                    type: "box",
                    layout: "vertical",
                    spacing: "sm",
                    margin: "lg",
                    contents: [
                      {
                        type: "box",
                        layout: "vertical",
                        margin: "xxl",
                        contents: [
                          {
                            type: "spacer",
                          },
                          {
                            type: "image",
                            url: `${galleries[0].img}`,
                            size: "full",
                            aspectRatio: "16:13",
                            aspectMode: "cover",
                          },
                          {
                            type: "box",
                            layout: "horizontal",
                            contents: [
                              {
                                type: "button",
                                action: {
                                  type: "uri",
                                  label: "คลิกที่นี่",
                                  uri: `${process.env.REACT_APP_PUBLIC_CONTANT}/${id}`,
                                },
                                style: "primary",
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          },
        ],
        {
          isMultiple: true,
        }
        // {
        //   "type": "button",
        //   "action":{
        //     "type":"url",
        //     "label" : "คลิกที่นี่",
        //     "uri":`${process.env.REACT_APP_PUBLIC_CONTANT}/${id}`,
        //   }
        // }
      )
      .then(function (res) {
        if (res) {
          // succeeded in sending a message through TargetPicker
          // console.log(`[${res.status}] Message sent!`);
        } else {
          // sending message canceled
          // console.log("TargetPicker was closed!");
        }
      })
      .catch(function (error) {
        // something went wrong before sending a message
        console.log("something wrong happen", error);
      });
  };
  // }
  const contentHeight = Math.ceil((83 / 100) * height);
  var style = {
    overflowX: "hidden",
    overflowY: "auto",
    height: `${contentHeight}px`,
  };

  return (
    <RegisterLayout title={"Content"}>
      <BannerDetail />
      <div className="bg-white mt-1 mx-2" id="content" style={style}>
        <div className="mx-auto">
          <div className="mx-2 pt-2 image-gallery-fb">
            <MuiFbPhotoGrid
              images={galleries} // require
              reactModalStyle={{ overlay: { zIndex: 2000 } }} // optional (https://github.com/reactjs/react-modal#styles)
            />
          </div>

          <div className="imgDetail">
            <div className="row py-2">
              <div className="">
                <>
                  <div className="fontawesome text-end d-flex align-items-center">
                    <NavLink to={""} className={"nav-link"}>
                      <FontAwesomeIcon
                        icon={faEye}
                        className="mx-1 text-info fs-5"
                      />
                    </NavLink>
                    <span className="text-primary fs-5 mx-2">{view}</span>
                    <br></br>
                    <Space className="mb-2">
                      <Button
                        type="dashed"
                        shape="circle"
                        icon={<ShareAltOutlined />}
                        onClick={shareGallery}
                      />
                      {/* <Button
                        type="dashed"
                        shape="circle"
                        icon={<DownloadOutlined />}
                        onClick={downloadGallery}
                      /> */}
                    </Space>
                  </div>
                </>
              </div>
              <div className="col-8 p-0 ps-2 lh-1 text-uppercase text-primary d-flex align-items-center fs-5">
                {name}
              </div>
            </div>
            <div className="row py-2">
              {/* <EditorContent editor={editor} /> */}
              {/* <RichEditor description={description} /> */}
              {parse(description)}
            </div>
          </div>
        </div>

        <div className="px-3 mt-4 mb-4">
          <h2 className="text-uppercase fw-bold text-primary m-0">
            {" "}
            related content{" "}
          </h2>
          <div className="row py-2">
            {relate.map((r) => (
              <div
                key={r.id}
                onClick={() => gotoRelate(r.id)}
                className="col-4 p-0 px-1"
              >
                <NavLink to={`/content/${r.id}`}>
                  <div className="img">
                    <img src={r.img} alt="" />
                  </div>
                  <div className="fontawesome pe-2">
                    <div to={""} className={"nav-link"}>
                      <FontAwesomeIcon
                        icon={faEye}
                        className="mx-1 text-info"
                      />
                    </div>
                    <span className="text-primary">{r.view}</span>
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      </div>

      <MenuMain />
    </RegisterLayout>
  );
};

export default ContentDetail;
