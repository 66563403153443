import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React, { useEffect } from "react";
import { Button, Card, Divider } from "antd";
import Link from '@tiptap/extension-link'


const RichEditor = ({ description }) => {

  // console.log(values.description)

  const editor = useEditor({
    extensions: [
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      Link.configure({
        openOnClick: true,
        HTMLAttributes: {
          rel: 'noopener noreferrer',
          target: '_blank',
        },
      }),
    ],
    editable: false,
  });

  useEffect(() => {
    if (!editor) {
      return undefined
    }

    editor.commands.setContent(description)
  }, [description])

  return (
    // <Card className="p-2">
    //   <EditorContent editor={editor} />
    // </Card>
    <>
    <EditorContent editor={editor} />
  </>
  );
};

export default RichEditor;
