import React from 'react'
import ProfileLayout_2 from '../layouts/ProfileLayout_2'
import { Link } from 'react-router-dom'
import Banner from '../layouts/Banner'
import { Select, Space } from 'antd';

const EditProfile_2 = () => {

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <>
        {/* <ProfileLayout_2 title={"Edit Profile"}>
          <Banner />
          <div className='container mx-auto vh-100'>
            <div className='row text-center mt-5'>
              <div className='col-2'><Link to="/editProfile"><img src="../assets/images/edit_profile/back.png" alt="" width={20} /></Link></div>
              <div className='col-8'><img src="../assets/images/edit_profile/textTitle.png" alt="" width={150} /></div>
              <div className='col-2'><Link><img src="../assets/images/edit_profile/share.png" alt="" width={20} /></Link></div>
            </div>

            <div className='text-center wrap-profile'>
              <div className='mx-auto wrapper'>
                <img src="../assets/images/edit_profile/profile.png" alt="" className='profile-pic' /> 
              </div> 
               <p className='pt-1'>เปลี่ยนรูป</p>
            </div>
             

            <div>
              <form className='d-flex flex-column w-100'>
                  <input type="text" name="" id="" style={{width: "80%"}} className='form-control bg-white my-1 px-2 py-2 rounded-5 border-0 mx-auto shadow text-center' placeholder='ที่อยู่' />
                  
                    <Space wrap className='d-flex justify-content-center w-100 rounded-pill'>
                      <Select
                        className='form-control bg-white my-2 px-2 py-1 rounded-5 border-0 mx-auto shadow text-center'
                        defaultValue="แขวง / ตำบล"
                        style={{width: 310, border: "none"}}
                        bordered={false}
                        onChange={handleChange}
                        options={[
                          {
                            value: 'jack',
                            label: 'Jack',
                          },
                          {
                            value: 'lucy',
                            label: 'Lucy',
                          },
                          {
                            value: 'Yiminghe',
                            label: 'yiminghe',
                          },
                          {
                            value: 'disabled',
                            label: 'Disabled',
                            disabled: true,
                          },
                        ]}
                      />
                    </Space>

                    <Space wrap className='d-flex justify-content-center w-100 rounded-pill'>
                      <Select
                        className='form-control bg-white my-2 px-2 py-1 rounded-5 border-0 mx-auto shadow text-center'
                        defaultValue="เขต / อำเภอ"
                        style={{width: 310, border: "none"}}
                        bordered={false}
                        onChange={handleChange}
                        options={[
                          {
                            value: 'jack',
                            label: 'Jack',
                          },
                          {
                            value: 'lucy',
                            label: 'Lucy',
                          },
                          {
                            value: 'Yiminghe',
                            label: 'yiminghe',
                          },
                          {
                            value: 'disabled',
                            label: 'Disabled',
                            disabled: true,
                          },
                        ]}
                      />
                    </Space>

                    <Space wrap className='d-flex justify-content-center w-100 rounded-pill'>
                      <Select
                        className='form-control bg-white my-2 px-2 py-1 rounded-5 border-0 mx-auto shadow text-center'
                        defaultValue="จังหวัด"
                        style={{width: 310, border: "none"}}
                        bordered={false}
                        onChange={handleChange}
                        options={[
                          {
                            value: 'jack',
                            label: 'Jack',
                          },
                          {
                            value: 'lucy',
                            label: 'Lucy',
                          },
                          {
                            value: 'Yiminghe',
                            label: 'yiminghe',
                          },
                          {
                            value: 'disabled',
                            label: 'Disabled',
                            disabled: true,
                          },
                        ]}
                      />
                    </Space>

                    <input type="text" name="" id="" style={{width: "80%"}} className='form-control bg-white my-1 px-2 py-2 rounded-5 border-0 mx-auto shadow text-center' placeholder='รหัสไปรษณีย์' />
                  
                  <div className='mx-auto mt-3'><Link to="/editProfile1"><img src="../assets/images/edit_profile/enter.png" alt="" width={180} /></Link></div>
              </form>
            </div>
          </div>
        </ProfileLayout_2> */}

        <div>
          <form className='d-flex flex-column w-100 mb-2'>
              <input type="text" name="" id="" style={{width: "80%"}} className='form-control bg-white my-1 px-2 py-2 rounded-5 border-0 mx-auto shadow text-center' placeholder='ที่อยู่' />
              
                <Space wrap direction="vertical" className='d-flex justify-content-center w-100 rounded-pill'>
                  <Select
                    className='form-control bg-white my-2 px-2 py-1 rounded-5 border-0 mx-auto shadow text-center'
                    defaultValue="จังหวัด"
                    style={{width: "80%",}}
                    bordered={false}
                    onChange={handleChange}
                    options={[
                      {
                        value: 'jack',
                        label: 'Jack',
                      },
                      {
                        value: 'lucy',
                        label: 'Lucy',
                      },
                      {
                        value: 'Yiminghe',
                        label: 'yiminghe',
                      },
                      {
                        value: 'disabled',
                        label: 'Disabled',
                        disabled: true,
                      },
                    ]}
                  />
                </Space>

                <Space wrap direction="vertical" className='d-flex justify-content-center w-100 rounded-pill'>
                  <Select
                    className='form-control bg-white my-2 px-2 py-1 rounded-5 border-0 mx-auto shadow text-center'
                    defaultValue="เขต / อำเภอ"
                    style={{width: "80%",}}
                    bordered={false}
                    onChange={handleChange}
                    options={[
                      {
                        value: 'jack',
                        label: 'Jack',
                      },
                      {
                        value: 'lucy',
                        label: 'Lucy',
                      },
                      {
                        value: 'Yiminghe',
                        label: 'yiminghe',
                      },
                      {
                        value: 'disabled',
                        label: 'Disabled',
                        disabled: true,
                      },
                    ]}
                  />
                </Space>

                <Space wrap direction="vertical" className='d-flex justify-content-center rounded-pill' style={{width: "100%"}} >
                  <Select
                    className='form-control bg-white my-2 px-2 py-1 rounded-5 border-0 mx-auto shadow text-center'
                    defaultValue="แขวง / ตำบล"
                    style={{width: '80%'}}
                    bordered={false}
                    onChange={handleChange}
                    options={[
                      {
                        value: 'jack',
                        label: 'Jack',
                      },
                      {
                        value: 'lucy',
                        label: 'Lucy',
                      },
                      {
                        value: 'Yiminghe',
                        label: 'yiminghe',
                      },
                      {
                        value: 'disabled',
                        label: 'Disabled',
                        disabled: true,
                      },
                    ]}
                  />
                </Space>

                <input type="text" name="" id="" style={{width: "80%"}} className='form-control bg-white my-1 px-2 py-2 rounded-5 border-0 mx-auto shadow text-center' placeholder='รหัสไปรษณีย์' />
              
              <div className='mx-auto mt-3'><Link to="/editProfile1"><img src="../assets/images/edit_profile/enter.png" alt="" width={180} /></Link></div>
          </form>
        </div>
    </>
  )
}

export default EditProfile_2