import axios from "axios";

export const getRegister = async (access_token, data) =>
  await axios.post(`${process.env.REACT_APP_API}/register`, data, {
    headers: {
      Authorization: access_token,
    },
  });

export const getLogin = async (access_token, data, access_token2) =>
  await axios.post(`${process.env.REACT_APP_API}/login`, data, {
    headers: {
      'Authorization': access_token,
      'Authorization-line': access_token2,
    },
  });

export const getProfile = async (authtoken, access_token) => {
  return await axios.get(`${process.env.REACT_APP_API}/profile`, {
    headers: {
      session: authtoken,
      Authorization: access_token,
    },
  });
};
