import React, { useEffect, useContext, useState } from "react";

import RegisterLayout from "../layouts/RegisterLayout";
import { Col, Row } from "antd";
import { UserContext } from "../../context";
import { getLogin } from "../../functions/profile";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const liff = window.liff;
  const [state, setState] = useContext(UserContext);
  const navigate = useNavigate();
  const REACT_APP_LINE_LIFF = "1661291001-WNVDV3Qn";

  //UAT1660831164-gbndMPGJ
  // const REACT_APP_LINE_LIFF = "1660831164-gbndMPGJ";

  // round 1 line login
  useEffect(() => {
    liff.init({ liffId: REACT_APP_LINE_LIFF }).then(async () => {
        if (liff.isLoggedIn()) {
          // Access value associated with the key
          const lineAccessToken = sessionStorage.getItem("lineAccessToken");
          const lineAccessToken2 = sessionStorage.getItem("lineAccessToken2");
          // const lineAccessToken2 = liff.getAccessToken();
          // sessionStorage.setItem("lineAccessToken2", lineAccessToken2);
          liff
            .getProfile()
            .then((profile) => {
              const userId = profile.userId;

              window.localStorage.setItem("displayName", profile.displayName);
              window.localStorage.setItem("pictureUrl", profile.pictureUrl);
              // navigate("/test");
              getLogin(lineAccessToken, { line_id: userId }, lineAccessToken2).then((res) => {
                if (res.status === 404) {
                  navigate("/register");
                } else if (res.status === 200) {
                  // update context
                  const data = {
                    user: res.data.profile,
                    token: res.data.data.session,
                  };

                  setState({
                    user: res.data.profile,
                    token: res.data.data.session,
                  });

                  window.localStorage.setItem("auth", JSON.stringify(data));

                  navigate("/home");
                }
              });
            })
            .catch((err) => console.error(err));
        }else {
          navigate("/", { replace: true });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <RegisterLayout title="Welcome">
      <div style={{ textAlign: "center" }} className="h-100">
        <Row justify="space-around" align="middle" className="h-100">
          <Col span={24}>
            <img
              src="../assets/images/page_point/logo.png"
              alt=""
              className="w-75"
            />
          </Col>
        </Row>
      </div>
    </RegisterLayout>
  );
};

export default Login;
