import MenuMain from "./MenuMain";
import Banner from "../layouts/Banner";
import Mainlayout from "../layouts/Mainlayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useNavigate } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import BannerMonthly from "../layouts/BannerMonthly";
import BannerCampaign from "../layouts/BannerCampaign";
import CardPointMember from "../layouts/CardPointMember";
import { getBanners } from "../../functions/banner";
import { checkQuestion, getQuestion } from "../../functions/question";
import { getProfile } from "../../functions/profile";
import { getContentRecommened, getCategory } from "../../functions/content";
import { getHomeConfig } from "../../functions/home";
import { UserContext } from "../../context/index";
import { getGameRanking } from "../../functions/gameRank";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal, Tooltip } from "antd";
import { getCheckAddress } from "../../functions/adddress";

const { confirm } = Modal;

const Home = () => {
  const [state, setState] = useContext(UserContext);
  const [userId, setUserId] = useState("");
  // state

  const navigate = useNavigate();
  //-- ตัวม็อก---//

  const authtoken = localStorage.getItem("authtoken");
  const accessToken = localStorage.getItem("accessToken");
  // const authtoken =
  //   "eyJhbGciOiJIUzI1NiJ9.HcRGY7uhRPt25VyyGoVWactkn7i9uvbj1clQItlrikVk2JLELjWnsF3_APfXnk9FhPWbNUPmkoIHWEv8ZXgsbwmyCSJzC61dpXsaEO7AnCDbx9IVeQH-E_iYbYk0OE1KLoc9jhlOWHXVMbnkCf0W_mhPVTXoQTXRwaoOA03So38.FL5NeXt8yyS5dUUlUPij0JSNwOy8oLj589Pj20ViGLI";
  // const accessToken =
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Mjk1ODEsIm1lbWJlcl9pZCI6NDY3LCJjcmVhdGVkX2RhdGUiOiIyMDIzLTA4LTI5IDE2OjA3OjM1IiwiZXhwaXJlX2RhdGUiOiIyMDIzLTA4LTMwIDA0OjA3OjM1In0.ov2fWKR5qXJT8JMBhA2gbM1PLTbdqLFCK6hsUKSiGhk";
  //---------//

  const displayName = localStorage.getItem("displayName");
  const pictureUrl = localStorage.getItem("pictureUrl");

  const [showMonthly, setShowMonthly] = useState(false);
  const [indexMonthly, setIndexMonthly] = useState(0);
  const [monthly, setMonthly] = useState([]);
  const [showCampaign, setShowCampaign] = useState(false);
  const [indexCampaign, setIndexCampaign] = useState(0);
  const [campaign, setCampaign] = useState([]);
  const [cateActive, setCateActive] = useState(0);
  const [point, setPoint] = useState("0");
  const [contents, setContents] = useState([]);
  const [heightDevice, setHeightDevice] = useState(true);
  const [category, setCategory] = useState([]);
  const checkIdGetPic = localStorage.getItem("auth");

  const obj = JSON.parse(checkIdGetPic);
  const [checkID, setCheckID] = useState([]);
  const [checkUseEffect, setCheckUseEffect] = useState(false);
  const [checkQuestions, setCheckQuestions] = useState(false);

  // const [addressStatus,setGetIdAddress] = useState(0);

  useEffect(() => {
    // check404();
    // console.log("Open Quiz");
    // return
    if (state && state.token) {
      setUserId(state.user.id);
      checkQuestion(state.token, state.id).then((res) => {
        if (res.status === 400) {
          navigate("/personality");
        }
        if (res.data.status === 404) {
          //-----ใส่ชั่วคราวเพราะว่า เอาการตอบคำถามออก-----//
          loadCheckAddress();
        } else {
          if (checkUseEffect === false) {
            //-----เช็ค เพราะว่าหลังจาก มีการตอบคำถามแล้ว-----//
            loadCheckAddress();
            setCheckUseEffect(true);
          }
        }

        //  --------เช็คคำถาม-----------------------------------//
        if (res.status === 200 || res.status === 201) {
          const data = res.data;
          console.log("TTTT", data);
          // setCheckQuestions(true);
          if (data.status === 404) {
            // navigate("/personality");
          }
        }
      });

      getQuestion(state.token).then((res) => {
        if (res.data.length > 0) {
          navigate("/Questions");
        }
        console.log("getQue", res);
      });

      loadHomeConfig();
      loadBanners();
      loadCategory();
      loadContents();
      loadProfile();
    }
    console.log("close Quiz");
  }, [state && state.token, point, navigate]);

  const check404 = async () => {
    checkQuestion(state.token).then((res) => {
      console.log("res.data.status_code :", res.data.status_code);
      console.log("res.status :", res.status);
    });
  };

  const loadCheckAddress = async () => {
    getCheckAddress(state.token).then((res) => {
      // setGetIdAddress(res.data.data.length);
      // console.log("xx",res.data);
      if (res.data.data.length === 0) {
        showPromiseConfirm();
      }
    });
  };

  const showPromiseConfirm = () => {
    let msgTitle =
      "กรุณากรอกที่อยู่ให้ครบถ้วน สำหรับจัดส่งของรางวัลในกรณีที่เป็นผู้โชคดีจากกิจกรรม";
    let path = "/editProfile";
    confirm({
      title: msgTitle,
      icon: <InfoCircleOutlined />,
      onOk() {
        return new Promise((resolve, reject) => {
          setTimeout(Math.random() > 0.1 ? resolve : reject, 100);
          navigate("/editProfile");
        }).catch(() => console.log("Oops errors!"));
      },
      onCancel() {},
    });
  };

  const loadProfile = () => {
    getProfile(state.token)
      .then((res) => {
        //
        let point = kFormatter(res.point);
        setPoint(point);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadHomeConfig = () => {
    getBanners(state.token).then((res) => {
      getHomeConfig(state.token).then((res) => {
        const data = res.data.data;

        setShowMonthly(data[0].monthly_active);
        // setShowMonthly(data[0].campaign_active);
        setShowCampaign(data[0].campaign_active);
        if (data[0].campaign_active || data[0].monthly_active) {
          setHeightDevice(false);
        }
      });
    });
  };

  const loadBanners = () => {
    getBanners(state.token).then((res) => {
      const data = res.data.data;
      setMonthly(data.data_monthly);
      // setMonthly(data.data_campaign);
      setCampaign(data.data_campaign);
    });
  };

  const loadCategory = () => {
    getCategory(state.token)
      .then((res) => {
        const data = res.data.data;

        // if (data) {
        //   setCateActive(data[0].id);
        // }
        setCategory(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadContents = () => {
    getContentRecommened(state.token)
      .then((res) => {
        const data = res.data.data;

        // let point = kFormatter(res.point);
        setContents(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const searched = (cateActive) => (c) =>
    cateActive !== 0 ? c.category_id === cateActive : c;

  const ButtonCategory = ({ id, name, isActive, onClick }) => {
    return (
      <li key={id} className={isActive ? "category-active" : ""}>
        <NavLink
          key={id}
          to={""}
          className="nav-link"
          onClick={() => handlerSelectCategory(id)}
        >
          {name}
        </NavLink>
      </li>
    );
  };

  const handlerSelectCategory = (id) => {
    setCateActive(id);
  };

  const handleMonthlySelect = (selectedIndex, e) => {
    setIndexMonthly(selectedIndex);
  };

  const handleCampaignSelect = (selectedIndex, e) => {
    setIndexCampaign(selectedIndex);
  };

  const kFormatter = (num) => {
    const digits = 1;
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item
      ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
      : "0";
  };

  const data = {
    username: displayName,
    img: pictureUrl,
  };

  return (
    <>
      <Mainlayout title={"Point"} height={heightDevice}>
        <Banner isIconRight="1" />
        {/* {JSON.stringify(monthly)} */}
        <div className="container mx-auto">
          <div>
            <CardPointMember point={point} data={data} className="bg-dander" />
            <img
              src="../assets/images/home/profile_.png"
              alt=""
              style={{ width: "100%" }}
            />
          </div>

          {showMonthly && (
            <BannerMonthly
              monthly={monthly}
              indexMonthly={indexMonthly}
              handleMonthlySelect={handleMonthlySelect}
              id={userId}
            />
          )}

          {showCampaign && (
            <BannerCampaign
              campaign={campaign}
              indexCampaign={indexCampaign}
              handleCampaignSelect={handleCampaignSelect}
              id={userId}
            />
          )}
          <div
            className="menu-category"
            style={{ overflow: "auto", whiteSpace: "nowrap" }}
          >
            <ul className="mb-0" style={{ display: "inline-flex" }}>
              <ButtonCategory
                id={0}
                name={"ทั้งหมด"}
                isActive={cateActive === 0}
                onClick={handlerSelectCategory}
              />
              {category
                .slice(0)
                .reverse()
                .map((c) => (
                  <ButtonCategory
                    {...c}
                    isActive={cateActive === c.id}
                    onClick={handlerSelectCategory}
                  />
                ))}
            </ul>
          </div>

          <div className="suggest bg-white">
            <div className="row mx-auto mt-1 pt-md-3 space">
              <div className="col-6 text-start">
                <div className=" ps-4">
                  <img
                    src="../assets/images/page_point/sug.png"
                    alt=""
                    className="img-sug"
                  />
                </div>{" "}
              </div>
              <div className="col-6 text-end text-primary">
                <NavLink className={"nav-link"} to={"/contents"}>
                  <span className="pe-4 readmore">ดูเพิ่มเติม..</span>
                </NavLink>
              </div>
            </div>

            <div className="row my-2 wrap-product">
              <div className="row product-list boximgHome">
                {contents.filter(searched(cateActive)).map((c) => (
                  <div className="col-6 mb-4 p-0 d-flex justify-content-center SubboximgHome">
                    <NavLink to={`/content/${c.id}`}>
                      <div className="img mx-auto">
                        <div className="img-pro mx-auto">
                          <img
                            src={c.img}
                            style={{ borderRadius: "12px" }}
                            alt=""
                          />
                        </div>
                        <div className="hot">
                          <img
                            src="../assets/images/page_point/hot.png"
                            alt=""
                          />
                        </div>
                        <div className="row">
                          <div className="col-5 p-0 ps-2 lh-1 text-uppercase text-primary d-flex align-items-center product-name"></div>
                          <div className="col-7">
                            <div className="fontawesome text-end">
                              <NavLink to={""} className={"nav-link"}>
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="mx-1 text-info"
                                />
                              </NavLink>
                              <span className="text-primary">{c.view}</span>
                              <NavLink to={""} className={"nav-link"}>
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  className="mx-1 text-info"
                                />
                              </NavLink>
                              <NavLink to={""} className={"nav-link"}>
                                <FontAwesomeIcon
                                  icon={faArrowUpRightFromSquare}
                                  className="mx-1 text-info"
                                />
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <MenuMain />
      </Mainlayout>
    </>
  );
};

export default Home;
