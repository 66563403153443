import DocumentTitle from "react-document-title";
import React from 'react';

const Mainlayout = ({ children, title, height }) => {
  const heightDevice = (height) ? "100vh" : "100%"
  var style = {
    backgroundImage: `url("../assets/images/page_point/bg_020.png")`,
    height: heightDevice
  }
  return (
    <>
      <DocumentTitle title={title} />
      <div
        className="position-relative vw-100 v-100 overflow-hidden bg-home"
        style={style}
      >
        {children}
      </div>
    </>
  );
};

export default Mainlayout;
