import React from "react";
import Resizer from "react-image-file-resizer";
import { Badge } from "antd";
import { DeleteOutlined } from "@ant-design/icons";


// const delRef = useRef();
const UploadImage_ = ({ img, setImg, inputRef }) => {
  const fileUploadAndResize = (e) => {
    let files = e.target.files;
    
    if (files) {
      for (let i = 0; i < files.length; i++) {
        Resizer.imageFileResizer(
          files[i],
          1000,
          1000,
          "JPEG",
          100,
          0,
          (uri) => {
            setImg(uri);
          },
          "base64"
        );
      }
    }
  };

  const handleImageRemove = () => {
    setImg("");
    window.location.href = "";
    // file
  };

  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      {img && (
        <center>
          <Badge
            count={
              <DeleteOutlined
                style={{
                  color: "#f5222d",
                }}
              />
            }
            className="m-3"
            onClick={() => handleImageRemove()}
            style={{ cursor: "pointer" }}
          >
            <img
              style={{ borderRadius: "25px", marginLeft: "1%",width:"100%",height:"250px" }}
              src={img}
              alt=""
            />
          </Badge>
        </center>
      )}
      {!img && (
        <center>
          <label style={{height:'300px'}} >
            <input
              type="file"
              id="upload"
              ref={inputRef}
              // hidden
              accept="image/*,.heic"
              onChange={fileUploadAndResize} 
              style={{display:'none',marginLeft:'8%'}}
            />
          </label>
        </center>
      )}
    </div>
  );
};

export default UploadImage_;
