import React, { useState, useEffect } from "react";
import Publie_BannerDetail from "../layouts/Publie_Baner";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
// import { MuiFbPhotoGrid } from "mui-fb-photo-grid";
import { getContentPublic } from "../../functions/content";
import { useParams } from "react-router-dom";
import RichEditor from "../layouts/RichEditor";
import { Carousel } from "antd";
import { Link } from "react-router-dom";

const Public_ContentDetail = () => {
  // const [state, setState] = useContext(UserContext);
  // state

  const { innerHeight: height } = window;
  const [galleries, setGalleries] = useState([]);
  const [name, setName] = useState("");
  const [view, setView] = useState("");
  const [description, setDescription] = useState("");
  const { id } = useParams();

  const contentStyle = {
    height: "400px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
    width: "500px",
  };

  useEffect(() => {
    loadData(id);
    // loadRelate(id);
  }, []);

  const loadData = (id) => {
    getContentPublic(id)
      .then((res) => {
        const data = res.data.data[0];
        // setContent(data[0])
        setName(data.title);
        setView(data.view);
        setDescription(data.description);
        if (data.gallery !== "" && data.gallery !== "[]") {
          let galleryData = JSON.parse(data.gallery);
          setGalleries(reFormatGallery(galleryData));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reFormatGallery = (data) => {
    let gallery = data.map(function (obj) {
      obj["title"] = obj["public_id"];
      obj["img"] = obj["url"];
      obj["imgThumbnail"] = obj["url"]; // Assign new key
      delete obj["public_id"];
      delete obj["url"]; // Delete old key
      return obj;
    });
    return gallery;
  };

  // }
  const contentHeight = Math.ceil((83 / 100) * height);
  var style = {
    // overflowX: "hidden",
    // overflowY: "auto",
    // height: `${contentHeight}px`,
  };

  return (
    <div
      title={"Content"}
      style={{
        backgroundImage: `url("../assets/images/shutterstock_715515577.png")`,
        backgroundSize: "cover",
        backgroundPosition: "cover",
      }}
    >
      <div className="sizeBG" style={{ margin: "auto" }}>
        <div className="bg-white mt-1 mx-2" id="content">
          <Publie_BannerDetail />
          <div className="mx-auto">
            <div className="mx-2 pt-2">
              {/* <MuiFbPhotoGriddi
              images={galleries} // require
            //   reactModalStyle={{ overlay: { zIndex: 2000 } }} // optional (https://github.com/reactjs/react-modal#styles)
            /> */}
              <center>
                <Carousel autoplay>
                  {galleries.map((c) => (
                    <div>
                      <img className="sizeImg" src={c.img} alt="" />
                    </div>
                  ))}
                  {/* <div>
                <h3 style={contentStyle}>2</h3>
                </div>
                <div>
                <h3 style={contentStyle}>3</h3>
                </div>
                <div>
                <h3 style={contentStyle}>4</h3>
                </div> */}
                </Carousel>
              </center>
            </div>

            <div className="imgDetail">
              <div className="row py-2">
                <div className="col-8 p-0 ps-2 lh-1 text-uppercase text-primary d-flex align-items-center fs-5">
                  {name}
                </div>
                <div className="col-4 p-0 pe-2">
                  <>
                    <div className="fontawesome text-end d-flex align-items-center">
                      <NavLink to={""} className={"nav-link"}>
                        <FontAwesomeIcon
                          icon={faEye}
                          className="mx-1 text-info fs-5"
                        />
                      </NavLink>
                      <span className="text-primary fs-5 mx-2">{view}</span>
                      {/* <Space className="mb-2">
                      <Button
                        type="dashed"
                        shape="circle"
                        icon={<ShareAltOutlined />}
                        onClick={shareGallery}
                      />
                      <Button
                        type="dashed"
                        shape="circle"
                        icon={<UploadOutlined />}
                        onClick={downloadGallery}
                      />
                    </Space> */}
                    </div>
                  </>
                </div>
              </div>
              <div className="row py-2">
                {/* <EditorContent editor={editor} /> */}
                <RichEditor
                  description={description}
                  style={{ float: "left" }}
                />
              </div>
              <center>
                <div>
                  <Link to="https://line.me/R/ti/p/@pttgroup">
                    <img src="../assets/images/add.png" alt="" width={200} />
                  </Link>
                </div>
              </center>
              <div style={{ height: "100px" }}></div>
            </div>
          </div>

          {/* <div className="px-3 mt-4 mb-4">
          <h2 className="text-uppercase fw-bold text-primary m-0">
            {" "}
            related content{" "}
          </h2>
          <div className="row py-2">
            {relate.map((r) => (
              <div
                key={r.id}
                onClick={() => gotoRelate(r.id)}
                className="col-4 p-0 px-1"
              >
                <NavLink to={`/content/${r.id}`}>
                  <div className="img">
                    <img src={r.img} alt="" />
                  </div>
                  <div className="fontawesome pe-2">
                    <div to={""} className={"nav-link"}>
                      <FontAwesomeIcon
                        icon={faEye}
                        className="mx-1 text-info"
                      />
                    </div>
                    <span className="text-primary">{r.view}</span>
                  </div>
                </NavLink>
              </div>
            ))}
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Public_ContentDetail;
