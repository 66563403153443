import { useState, createContext, useEffect } from "react";
import axios from "axios";
import React from "react";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  // var auth = {
  //   user: {
  //     id: 29,
  //     first_name: "TzG",
  //     last_name: "afdsdssf",
  //     email: "asd1@dsfsdfs.com",
  //   },
  //   token:
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MTQyMiwibWVtYmVyX2lkIjoyOSwiY3JlYXRlZF9kYXRlIjoiMjAyMy0wOS0wNiAxMToyNzowOSIsImV4cGlyZV9kYXRlIjoiMjAyMy0wOS0wNiAyMzoyNzowOSJ9.m4F1vAN8Pbvh2UTnTHL71Xp_OToRDVyy24B2d2smOWU",
  // };
  //--ตัวจริง------//
  const [state, setState] = useState(
    JSON.parse(window.localStorage.getItem("auth"))
  );
  //--ตัวจริง------//
  useEffect(() => {
    //-----UAT-----//
    // localStorage.setItem("auth", JSON.stringify(auth));
    //-----UAT-----//
    setState(JSON.parse(window.localStorage.getItem("auth")));
  }, []);

  const token = state && state.token ? state.token : "";
  axios.defaults.baseURL = process.env.NEXT_PUBLIC_API;
  axios.defaults.headers.common["session"] = `${token}`;

  axios.interceptors.response.use(
    function (response) {
      // Do something before request is sent
      return response;
    },
    function (error) {
      // Do something with request error
      let res = error.response;

      // if (res.status === 401 && res.config && !res.config.__isRetryRequest) {
      if (res.status === 403) {
        setState(null);
        window.localStorage.removeItem("auth");
        window.location.href = "login";
      } else {
        return res;
      }
    }
  );

  return (
    <UserContext.Provider value={[state, setState]}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
