import axios from "axios";

export const getProvince = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/province`, {
    headers: {
      session: authtoken,
    },
  });
};

export const getDistricts = async (authtoken, id) => {
  return await axios.get(`${process.env.REACT_APP_API}/districts`, {
    headers: {
      session: authtoken,
    },
    params: {
      id: id,
    },
  });
};

export const getSubdistricts = async (authtoken, id) => {
  return await axios.get(`${process.env.REACT_APP_API}/subdistricts`, {
    headers: {
      session: authtoken,
    },
    params: {
      id: id,
    },
  });
};

export const getZipcode = async (authtoken, subDistrict_id) => {
  return await axios.get(`${process.env.REACT_APP_API}/zipcode`, {
    headers: {
      session: authtoken,
    },
    params: {
      id: subDistrict_id,
    },
  });
};

export const getEditProfile_ = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/profile`, {
    headers: {
      session: authtoken,
    },
  });
};

export const editUser = async (authtoken, editUser_) =>
  await axios.patch(`${process.env.REACT_APP_API}/profile/edit`, editUser_, {
    headers: {
      session: authtoken,
    },
  });

export const editAddress = async (authtoken, addressID) =>
  await axios.patch(`${process.env.REACT_APP_API}/address/edit`, addressID, {
    headers: {
      session: authtoken,
    },
  });

export const getCheckAddress = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/address`, {
    headers: {
      session: authtoken,
    },
  });
};

export const addAddress = async (authtoken, addAddress_) =>
  await axios.post(`${process.env.REACT_APP_API}/address/add`, addAddress_, {
    headers: {
      session: authtoken,
    },
  });

export const getAddressFormID_ = async (authtoken, id) => {
  return await axios.get(`${process.env.REACT_APP_API}/address/id`, {
    headers: {
      session: authtoken,
    },
    params: {
      id: id,
    },
  });
};
