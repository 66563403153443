import React from 'react';
import { Col, Row } from "antd";

const Banner = ({ isIconRight }) => {
  return (
    <>
      <Row justify="space-between px-3">
        <Col span={8} style={{ textAlign: "left" }}>
          <img
            src="../assets/images/page_point/logo_PTT_3.png"
            alt="PTT"
            className="w-75 mt-4"
          />
        </Col>
        <Col span={8} offset={8} style={{ textAlign: "right" }}>
          {isIconRight && (
            <img
              src="../assets/images/page_point/logo_p2_2.png"
              alt="campaign"
              className="w-75 mt-4"
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default Banner;
