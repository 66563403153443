import React from "react";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";

const BannerCampaign = ({
  campaign,
  indexCampaign,
  handleCampaignSelect,
  id,
}) => {
  const liff = window.liff;

  const onClickCampaign = () => {
    // window.open(`http://www.godjixptt45th.com/?line_id=${id}`, "_blank");
    liff.openWindow({
      url: `http://www.godjixptt45th.com/?line_id=${id}`,
      external: true,
    });
  };
  
  return (
    <div className="campaign mb-2">
      <h3 className="text-uppercase text-end fs-5 text-white pe-5 pt-2 mb-0">
        campaign
      </h3>

      <Carousel activeIndex={indexCampaign} onSelect={handleCampaignSelect}>
        {campaign.map((m) => (
          <Carousel.Item className="">
            <div
              key={m.id}
              className="monthly-height"
              onClick={() => onClickCampaign()}
            >
              <img className="d-block w-100 p-1" src={m.img} alt={m.title} />
            </div>
            {/* <Carousel.Caption></Carousel.Caption> */}
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default BannerCampaign;
