import React, { useContext, useState, useEffect } from "react";
import { Col, Divider, Row } from "antd";
import { getEditProfile_ } from "../../functions/adddress";
import { UserContext } from "../../context/index";

import "../../member.css";

const CardPointMember = ({ data }) => {
  const [state, setState] = useContext(UserContext);
  useEffect(() => {
    loadEditProfile();
  }, []);
  // const authtoken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6NDU4LCJtZW1iZXJfaWQiOjE4LCJjcmVhdGVkX2RhdGUiOiIyMDIzLTA1LTIzIDE3OjMyOjQ4IiwiZXhwaXJlX2RhdGUiOiIyMDIzLTA1LTI0IDA1OjMyOjQ4In0.hjd8HEFEyEqmHxeY2O2W9psOhZVavuaxpaeLYTKrv7Q";
  // const authtoken = localStorage.getItem("authtoken");
  const [profile, setProfile] = useState([]);
  const [point, setPoint] = useState(0);

  const loadEditProfile = async () => {
    if (state && state.token) {
      getEditProfile_(state.token).then((res) => {
        // setGetEditProfile(res.data.data);
        if (res.data.data.total_point === null) {
          setPoint(0);
        } else {
          setPoint(res.data.data.total_point);
        }
      });
    }
  };

  return (
    <>
      <div className="row w-50 text-white counter mx-auto">
        <div className="col-3 border-end border-white my-2"></div>
        <div className="col-9 pe-0 CardMemberTopMargin">
          <span className="title-count">จำนวนคะแนนทั้งหมด</span>
          <div className="row">
            <div className="col text-end pe-1" style={{ marginTop: "6%" }}>
              <h3 className=" m-0 fw-bold lh-1">
                {point.toLocaleString("en-US")}
              </h3>
            </div>
            <div className="col text-start p-0 d-flex flex-column justify-content-center">
              <img
                src="../assets/images/page_point/star.png"
                alt=""
                className="star ps-1"
              />
              <span className="unit lh-1">คะแนน</span>
            </div>
          </div>
        </div>

        <div className="col-12">
          <img className="img_member" src={data.img} />
          {/* <img className="img_member" src="https://profile.line-scdn.net/0hAHWkBndpHmd6Egq4aRBgGApCHQ1ZY0d1UnFZBB0XRAdAd15hVycFVEpCEF5Edls3V3EBU08XFQB2AWkBZETiU30iQFBAIlo5VnVQgQ" /> */}

          <h5 className="username">{data.username}</h5>
          {/* <h5 className="username">kanomwarn</h5> */}
        </div>
      </div>
    </>
  );
};

export default CardPointMember;
