import React from 'react';
import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";
import { Input } from "antd";
const { Search } = Input;


const BannerContent = ({ keyword, setKeyword }) => {
  const handleSearchChange = (e) => {
    e.preventDefault();
    setKeyword(e.target.value.toLowerCase());
  };

  return (
    <>
      <Row justify="space-between px-3">
        <Col span={8} style={{ textAlign: "left" }}>
          <img
            src="../assets/images/page_point/logo_PTT_3.png"
            alt="PTT"
            className="w-75 mt-4"
          />
        </Col>
        <Col span={16} style={{ textAlign: "right" }}>
          <div className="mt-4">
            <Row align="bottom">
              <Col flex="auto">
                <Search
                  bordered={false}
                  allowClear
                  className="form-control text-center rounded-pill border-0"
                  // onSearch={onSearch}
                  // style={{
                  //   marginRight: "1px",
                  // }}
                  value={keyword}
                  onChange={handleSearchChange}
                />
              </Col>
            </Row>
          </div>

          {/* <div className="mt-4">
            <form action="" className="d-flex align-items-center">
              <div className="wrapper-search border rounded-pill border-primary d-flex align-items-center me-1">
                <input type="search" className="border-0 ms-2" />{" "}
                <img
                  src="../assets/images/page_article/search.png"
                  alt=""
                  className="search-icon"
                />
              </div>
              <NavLink to={"/Point"}>
                <img
                  src="../assets/images/page_article/arrow_p3.png"
                  alt=""
                  className="arrow-back px-1"
                />
              </NavLink>
            </form>
          </div> */}
        </Col>
      </Row>
    </>
  );
};

export default BannerContent;
