import React, { useState, useEffect, useContext } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { NavLink, useNavigate } from "react-router-dom";
import RegisterLayout from "../layouts/RegisterLayout";
import { Col, Row, Modal, Select, Space, Checkbox } from "antd";
import { toast } from "react-toastify";
import { saveDemo } from "../../functions/question";
import { UserContext } from "../../context";
import Banner from "../layouts/Banner";
import SelectReact from "react-select";
import { getProvince } from "../../functions/adddress";

const { confirm } = Modal;

const Personality = () => {
  const [state, setState] = useContext(UserContext);
  // state;

  const navigate = useNavigate();
  const authtoken = localStorage.getItem("authtoken");
  const [isAnswer, setIsAnswer] = useState(false);
  const [province_id, setProvince] = useState(0);
  const [gender, setGender] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [study_, setStudy] = useState("");
  const handleChange = (e) => {
    setGender(e.target.value);
    // console.log(e.target.value);
    if (study_ && province_id && e.target.value) {
      setIsAnswer(true);
    }
  };

  const handleChangeStudy = (e) => {
    setStudy(e.value);
    // console.log(e.value);
    if (province_id && gender && e.value) {
      setIsAnswer(true);
    }
  };

  const handleChangeProvince = (e) => {
    setProvince(e.value);
    // console.log(e.value);
    if (study_ && gender && e.value) {
      setIsAnswer(true);
    }
  };

  const loadProvince = async () => {
    getProvince(state.token).then((res) => {
      // console.log(res);
      const options = res.data.data.map((item) => {
        return {
          value: item.id,
          label: item.name_th,
        };
      });

      setProvinces(options);
    });
  };

  const study = [
    { value: "associate", label: "ต่ำกว่าปริญญาตรี" },
    { value: "bachelor", label: "ปริญญาตรี" },
    { value: "master", label: "ปริญญาโท" },
    { value: "doctor", label: "ปริญญาเอก" },
  ];

  useEffect(() => {
    setState(JSON.parse(window.localStorage.getItem("auth")));
    loadProvince();
  }, [state && state.token]);

  const showConfirm = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "ส่งคำตอบ",
      okText: "ยืนยัน",
      centered: true,
      cancelText: "ยกเลิก",
      onOk() {
        saveDemo(state.token, {
          gender: gender,
          education: study_,
          province: province_id,
        }).then((res) => {
          if (res.status === 404) {
            toast.error("Something went wrong!");
          } else if (res.status === 200 || res.status === 201) {
            navigate("/questions");
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <RegisterLayout title="Questions">
      <Banner />
      <div style={{ textAlign: "center" }} className="mt-3">
        <Row>
          <Col span={24}>
            <img
              src="../assets/images/page_point/logo.png"
              alt=""
              className="w-50"
            />
          </Col>
          <Col span={24}>
            <img
              src="../assets/images/page_sky/article-sky.png"
              alt=""
              className="w-75"
            />
          </Col>
          <Col span={24} style={{ marginTop: "10px" }}>
            <br></br>
          </Col>
        </Row>
      </div>
      <div className="container">
        <div>
          <div style={{ height: "10px" }}></div>
          <div style={{ width: "80%", textAlign: "center", margin: "auto" }}>
            <div style={{ marginBottom: "10px" }}>
              <input
                type="radio"
                name="gender"
                value="female"
                onChange={handleChange}
              />
              &nbsp;&nbsp;หญิง&nbsp;&nbsp;
              <input
                type="radio"
                name="gender"
                value="male"
                onChange={handleChange}
              />
              &nbsp;&nbsp;ชาย&nbsp;&nbsp;
              <input
                type="radio"
                name="gender"
                value="other"
                onChange={handleChange}
              />
              &nbsp;&nbsp;LGBTQ+
            </div>
            <SelectReact
              placeholder="ระดับการศึกษา..."
              options={study}
              onChange={handleChangeStudy}
            />
            <div style={{ height: "10px" }}></div>

            <SelectReact
              placeholder="จัดหวัด..."
              options={provinces}
              onChange={handleChangeProvince}
            />
          </div>
          <center>
            <div className="col-12 mt-3 p-0 ">
              <div className="row">
                <div className="">
                  {isAnswer === false && (
                    <button
                      disabled
                      className="bg-secondary py-2 rounded-pill border-0"
                      style={{ width: "120px" }}
                      onClick={showConfirm}
                    >
                      <img
                        style={{ height: "20px" }}
                        src="../assets/images/page_sky/send.png"
                        alt=""
                        className="send"
                      />
                    </button>
                  )}

                  {isAnswer === true && (
                    <button
                      className="bg-primary  py-2 rounded-pill border-0"
                      style={{ width: "120px" }}
                      onClick={showConfirm}
                    >
                      <img
                        style={{ height: "20px" }}
                        src="../assets/images/page_sky/send.png"
                        alt=""
                        className="send"
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </center>
          <div className="row wrapper-sky2 mx-auto"></div>
        </div>
      </div>
    </RegisterLayout>
  );
};

export default Personality;
