import { Routes, Route } from "react-router-dom";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { UserProvider } from "./context";
import Contents from "./components/shared/Contents";
import ContentDetail from "./components/shared/ContentDetail";
import Home from "./components/shared/Home";
import Questions from "./components/shared/Questions";
import Register from "./components/shared/Register";
import LineLogin from "./components/shared/LineLogin";
import LineLogin2 from "./components/shared/LineLogin2";
import LineVerifySuccess from "./components/shared/LineVerifySuccess";
import LineVerifySuccess2 from "./components/shared/LineVerifySuccess2";
import ShareGallery from "./components/shared/ShareGallery";
import Login from "./components/shared/Login";
import EditProfile from "./components/shared/EditProfile";
import EditProfile_2 from "./components/shared/EditProfile_2";
import UploadImage from "./components/shared/UploadImage";
import Game from "./components/shared/Game";
import Public_ContentDetail from "./components/shared/public_ContentDetail";
import Personality from "./components/shared/personality";


const LinkPath = () => {
  return (
    <UserProvider>
      <ToastContainer />
      <Routes>
        <Route path="/" exact element={<LineLogin />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/lineVerifySuccess" exact element={<LineVerifySuccess />} />
        <Route path="/register" exact element={<Register />} />
        <Route path="/home" exact element={<Home />} />
        <Route path="/contents" exact element={<Contents />} />
        <Route path="/questions" exact element={<Questions />} />
        <Route path="/content/:id" exact element={<ContentDetail />} />
        <Route path="/PublicContent/:id" exact element={<Public_ContentDetail />} />
        <Route path="/thankyou/:id" exact element={<ShareGallery />} />
        <Route path="/editProfile" exact element={<EditProfile />} />
        <Route path="/editProfile1" exact element={<EditProfile_2 />} />
        <Route path="/upload" exact element={<UploadImage />} />
        <Route path="/game" exact element={<Game />} />
        <Route path="/personality" exact element={<Personality />} />
        <Route path="/auth-line" exact element={<LineLogin2 />} />
        <Route path="/lineVerifySuccess2" exact element={<LineVerifySuccess2 />} />
      </Routes>
    </UserProvider>
  );
};
export default LinkPath;
