import RegisterLayout from "../layouts/RegisterLayout";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useContext } from "react";
import { Col, Row } from "antd";
import { UserContext } from "../../context";

const LineLogin = () => {
  const liff = window.liff;
  const [state, setState] = useContext(UserContext);

  const REACT_APP_LINE_LIFF = "1661291001-WNVDV3Qn";

  const REACT_APP_LINE_LIFF_real = "2001983007-MYepogoB";

  //UAT1660831164-gbndMPGJ
  //  const REACT_APP_LINE_LIFF = "1661291001-WNVDV3Qn";

  // const REACT_APP_LINE_LIFF_real = "2001077963-oOR5X8Z7";
  useEffect(() => {
    liff
      .init({ liffId: REACT_APP_LINE_LIFF })
      .then(async () => {
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          // login
          const lineAccessToken = liff.getAccessToken();
          sessionStorage.setItem("lineAccessToken", lineAccessToken);
          // navigate("/login", { replace: true });
          // navigate("/auth-line");
          // navigate("https://liff.line.me/2001077963-oOR5X8Z7", { replace: true })
          window.location.replace(
            `https://liff.line.me/${REACT_APP_LINE_LIFF_real}`
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [state]);

  return (
    <RegisterLayout title="Welcome">
      <div style={{ textAlign: "center" }} className="h-100">
        <Row justify="space-around" align="middle" className="h-100">
          <Col span={24}>
            <img
              src="../assets/images/page_point/logo.png"
              alt=""
              className="w-75"
            />
          </Col>
        </Row>
      </div>
    </RegisterLayout>
  );
};

export default LineLogin;
