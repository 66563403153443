import axios from "axios";

// export const inputImg = async (authtoken, addImg) =>
//     await axios.post(`${process.env.REACT_APP_API}/game/event`, addImg, {
//         headers: {
//             session: authtoken,
//         },

//     });

export const inputImg = async (authtoken, addImg) => {
  return await axios.post(`${process.env.REACT_APP_API}/game/event`, addImg, {
    headers: {
      session: authtoken,
    },
  });
};

export const uploadImgV2 = async (data) => {
  await fetch(
    `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
    {
      method: "post",
      body: data,
    }
  );
};

export const getCheckImg = async (authtoken) => {
  return await axios.get(`${process.env.REACT_APP_API}/check/event`, {
    headers: {
      session: authtoken
    },
  });
};
