import React, { useContext, useState } from "react";
import { UserContext } from "../../context";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { NavLink } from "react-router-dom";

const BannerMonthly = ({ monthly, indexMonthly, handleMonthlySelect, id }) => {
  const [state, setState] = useContext(UserContext);
  const liff = window.liff;
  // const [id, setId] = useState(state.user.id);

  const onClickCampaign = () => {
    // window.open(`http://www.godjixptt45th.com/?line_id=${id}`, "_blank");
    liff.openWindow({
      url: `http://www.godjixptt45th.com/?line_id=${id}`,
      external: true,
    });
  };

  return (
    <div className="monthly mb-2">
      <h3 className="text-uppercase text-end fs-5 text-white pe-5 pt-2 mb-0">
        GODJI GAME
      </h3>

      <Carousel activeIndex={indexMonthly} onSelect={handleMonthlySelect}>
        {monthly.map((m) => (
          <Carousel.Item className="">
            {/* <NavLink to={"http://www.godjixptt45th.com/?line_id="+id}> */}
            <div
              key={m.id}
              className="monthly-height"
              onClick={() => onClickCampaign()}
            >
              <img className="d-block w-100 p-1" src={m.img} alt={m.title} />
            </div>
            {/* </NavLink>  */}
            {/* <Carousel.Caption></Carousel.Caption> */}
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default BannerMonthly;
