// import DocumentTitle from "react-document-title";
// import React from 'react';

// const RegisterLayout = ({ children, title }) => {

//   var style = {
//     backgroundImage: `url("../assets/images/page_sky/Page_01.png")`
//   }

//   return (
//     <>
//       <DocumentTitle title={title} />
//       <div
//         className="position-relative vw-100 vh-100 overflow-hidden bg-home"
//         style={style}
//       >
//         {children}
//       </div>
//     </>
//   );
// };

// export default RegisterLayout;

import DocumentTitle from "react-document-title";
import React from "react";

const RegisterLayout = ({ children, title }) => {
  var style = {
    backgroundImage: `url("../assets/images/page_sky/Page_01.png")`,
  };

  return (
    <>
      <DocumentTitle title={title} />
      <div className="position-relative vw-100 vh-100 overflow-scoll bg-home">
        {children}
      </div>
    </>
  );
};

export default RegisterLayout;
