import React from 'react'
import DocumentTitle from "react-document-title";

const UploadLayout = ({children, title}) => {

    var style = {
        backgroundImage: `url("../assets/images/upload_smoke/bg.png")`,
        height: "100vh"
    }

  return (
    <>
        <DocumentTitle title={title} />
        <div style={style} className='position-relative overflow-hidden bg-home'>
            {children}
        </div>
    </>
  )
}

export default UploadLayout