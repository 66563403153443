import React, { useState, useEffect, useContext } from "react";
import GameLayout from "../layouts/GameLayout";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getGameRanking } from "../../functions/gameRank";
import { getTokenGame } from "../../functions/game";
import Banner from "../layouts/Banner";
import { UserContext } from "../../context";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { getEditProfile_ } from "../../functions/adddress";
import CardPointMember from "../layouts/CardPointMember";
import MenuMain from "./MenuMain";

const Game = () => {
  const [state, setState] = useContext(UserContext);


  const handleGameClick = (e) => {
    // prevent navigation
    e.preventDefault();

    getTokenGame(state.token)
      .then((res) => {
        const data = res.data.data;

        if (res.data.status_code === 200 || res.data.status_code === 201) {
          window.open(`https://ptt-game-fishing.web.app/?ID=${data}`, "_blank");

        } else {
          toast.error(
            <div>
              คุณเล่นครบแล้ว<br></br>สามารถเล่นเกม Godji
              เก็บขยะใต้ทะเลได้สัปดาห์ละ 4 ครั้ง เท่านั้น
            </div>
          );
        }
      })
      .catch((err) => {
        // toast.error("Something went wrong");
      });

    // 👇️ refers to the link element
  };

  const Banner_css = {
    width: "100%",
    height: "50%",
    objectFit: "cover",
    border: "3px solid #fff",
  };
  const [ranking, setRanking] = useState([]);

  //ตัวม็อก--------------
  // localStorage.setItem(
  //   "pictureUrl",
  //   "https://profile.line-scdn.net/0hoqbFRqeCMF5PPyJ3xslOIT9vMzRsTmlMal0qb38_bmclCHABZwx2bXI_aWp2X3IAZg53OntqPTpDLEc4UWnMakgPbml1Dn4KY1l4vQ"
  // );
  // var auth = {
  //   user: {
  //     id: "29",
  //     first_name: "Pongsakornaa",
  //     last_name: "Namisaa",
  //     email: "awdwa@asd.caaa",
  //   },
  //   token:
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6Mjk1NDksIm1lbWJlcl9pZCI6NDY3LCJjcmVhdGVkX2RhdGUiOiIyMDIzLTA4LTI5IDE1OjMzOjI0IiwiZXhwaXJlX2RhdGUiOiIyMDIzLTA4LTMwIDAzOjMzOjI0In0.bHyyLABnkGHXlULgtFEahwffY4lCyK9qt3NuA_ytO70",
  // };
  // localStorage.setItem("displayName", "TONGz");
  // localStorage.setItem("auth", JSON.stringify(auth));
  // ------------------

  const imgdefault = "../assets/images/ranking/profile.png";
  const imgProfile = localStorage.getItem("pictureUrl");
  const checkIdGetPic = localStorage.getItem("auth");
  const displayName = localStorage.getItem("displayName");

  const obj = JSON.parse(checkIdGetPic);
  const [checkID, setCheckID] = useState([]);
  const [event_, setEvent] = useState(0);
  const [point, setPoint] = useState("0");

  const data = {
    username: displayName,
    img: imgProfile,
  };

  useEffect(() => {
    // alert("ปิดปรับปรุงชั่วคราว");
    // window.location.href = "/home";

    loadRanking();
    // const checkID = obj.user.id;
    setCheckID(obj.user.id);
    loadEditProfile();
  }, [state && state.token]);

  const loadRanking = async () => {
    getGameRanking(state.token).then((res) => {
      setRanking(res.data.data);
    });
  };

  const loadEditProfile = async () => {
    getEditProfile_(state.token).then((res) => {
      // setGetEditProfile(res.data.data);
      setEvent(res.data.data.event);
    });
  };

  const handleUpImg = (e) => {
    if (event_ <= 5) {
      window.location.href = "/upload";
    } else {
      alert("คุณอัพโหลดครบ 5 ครั้งแล้ว");
    }
  };

  return (
    <>
      <GameLayout title={"Game Page"}>
        <Banner isIconRight="1" />
        <div className="container mx-auto">
          <div>
            <CardPointMember point={point} data={data} />
            <img
              src="../assets/images/home/profile_.png"
              alt=""
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center pt-2">
          <img
            src="../assets/images/upload_smoke/2-RewardBannerWeb-game2.png"
            alt=""
            style={Banner_css}
          />
        </div>

        {/* <div className="d-flex justify-content-between py-3 click-game"> */}
        <center>
          <div className="click-game">
            <a onClick={handleGameClick}>
              <br></br>
              <img
                style={{ marginBottom: "5px", width: "180px" }}
                src="../assets/images/ranking/Start_bottom.png"
                alt=""
              />
            </a>
            <br></br>
            <Link onClick={handleUpImg}>
              <img
                style={{ width: "180px" }}
                src="../assets/images/ranking/point_bottom.png"
                alt=""
              />
            </Link>
            <div>
              <Popup
                trigger={
                  <button
                    style={{
                      marginTop: "4px",
                      backgroundColor: "transparent",
                      border: "0",
                    }}
                  >
                    {" "}
                    <img
                      style={{ width: "180px", height: "35px" }}
                      src="../assets/images/ranking/rule_bottom.png"
                      alt=""
                    />
                  </button>
                }
                position="center"
                width={350}
              >
                <div
                  className="d-flex justify-content-center"
                  style={{ backgroundColor: "transparent", border: "0" }}
                >
                  {/* <img src="../assets/images/ranking/rules.png" alt="" width={320} />
                <div className='content-rules'>
              
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque ullam voluptatibus dicta animi 
                      modi omnis distinctio! Non atque velit praesentium cum quo maiores inventore porro deleniti libero. 
                      Est modi suscipit iure incidunt nostrum maiores, sequi velit possimus molestiae 
                      perspiciatis amet iste voluptatem aliquid dolores nobis veniam? Magnam inventore ipsum porro!</p>
                </div> */}
                  {/* backgroundImage:`url("../assets/images/ranking/rules.png")` */}
                  <div className="rules">
                    <div
                      style={{
                        marginTop: "30%",
                        color: "#FFF",
                        paddingLeft: "5%",
                        paddingRight: "5%",
                        overflow: "scroll",
                        height: "250px",
                      }}
                    >
                      1. กติกา<br></br>
                      1.1 เล่นเกมและสะสมคะแนนได้ตั้งแต่วันที่ 16 สิงหาคม 2566 –
                      30 กันยายน 2566 เวลา 23.59 น. โดย สามารถเล่นได้ 4 ครั้ง/
                      สัปดาห์/ คน เริ่มนับรอบสัปดาห์ตั้งแต่เวลา 00.01
                      ของวันจันทร์ ถึงเวลา 23.59 ของวันอาทิตย์<br></br>
                      1.2 ผู้เล่นเกมจะต้อง “เก็บขยะใต้ท้องทะเล” ให้ได้มากที่สุด
                      (กระป๋อง ขวดพลาสติก ยางรถยนต์) ภายในระยะเวลาที่กำหนดคือ 60
                      วินาที
                      หากเก็บขยะได้ตลอดจะสามารถรักษาแถบหลอดพลังไม่ให้ลดลงเร็วได้{" "}
                      <br></br>
                      1.3 ผู้เล่นเกมสามารถยิงแหโดยแตะส่วนใดก็ได้บนหน้าจอ
                      แหจะพุ่งไปเก็บขยะ ทั้งนี้ จะต้อง
                      “ไม่ให้แหไปจับโดนปลาและเต่า”
                      เนื่องจากจะทำให้แถบหลอดพลังลดลงเร็วขึ้น <br></br>
                      1.4 ผู้เล่นเกมสามารถสะสมคะแนนพิเศษเพิ่มได้
                      โดยการอัปโหลดรูปภาพ “สิ่งของที่นำกลับมา Reuse
                      ใช้ในชีวิตประจำวันได้” ที่ปุ่ม “คะแนนพิเศษ” โดยจะได้รับ
                      300 คะแนน ต่อการอัปโหลดรูปภาพ 1 ครั้ง<br></br>- อัปโหลดได้
                      5 ครั้ง/ผู้เล่น ตลอดระยะเวลากิจกรรม <br></br>-
                      คะแนนพิเศษจะถูกอัปเดตหลังเวลา 18.00 น. ของทุกวัน<br></br>-
                      กรณีรูปภาพที่อัปโหลดไม่ถูกต้องตามเงื่อนไขที่กำหนด
                      จะไม่ได้รับคะแนนพิเศษ<br></br>
                      1.6 คะแนนสะสมจะแสดงในแถบ Ranking List<br></br>
                      1.7 ผู้เล่นเกมที่ได้คะแนนสะสมสูงสุด 20 ลำดับแรก
                      ตลอดระยะเวลากิจกรรม มีสิทธิ์รับของรางวัลตามที่ ปตท.
                      กำหนดจำนวน 20 รางวัล<br></br>- รางวัลที่ 1 : Apple AirPods
                      Pro (Gen 2) มูลค่า 8,990.- บาท<br></br>- รางวัลที่ 2 :
                      เครื่องฟอกอากาศ Xiaomi Mi Air Purifier 4 Lite มูลค่า 3,999
                      บาท<br></br>- รางวัลที่ 3 : Apple AirTag มูลค่า 1,190 บาท
                      <br></br>- รางวัลที่ 4 – 10 : หม้อทอดไร้น้ำมันอัตโนมัติ
                      Simplus 5L มูลค่า 899 บาท จำนวน 7 รางวัล<br></br>-
                      รางวัลที่ 11 – 20 : กระเป๋าผ้า GodJi มูลค่า 450 บาท จำนวน
                      10 รางวัล<br></br>
                      <br></br>
                      2. นโยบายความเป็นส่วนตัว<br></br>
                      <br></br>
                      บริษัท ปตท. จำกัด (มหาชน) (ปตท.)
                      ให้ความสำคัญเป็นอย่างยิ่งกับการคุ้มครองข้อมูลส่วนบุคคลของท่าน
                      และจะให้ความคุ้มครองและปฏิบัติต่อข้อมูลส่วนบุคคลของท่านโดยสอดคล้องกับกฎหมายว่าด้วยการคุ้มครองข้อมูลส่วนบุคคล
                      ซึ่งท่านสามารถศึกษารายละเอียดเพิ่มเติมได้ที่
                      https://pttpdpa.pttplc.com/Privacy/146156 <br></br>
                      2.1 ปตท.
                      จะเก็บรวบรวมข้อมูลของท่านเพื่อจัดส่งของรางวัลในกรณีที่ท่านเป็นผู้โชคดีในกิจกรรม
                      เท่านั้น<br></br>
                      2.2 ปตท. จะไม่แบ่งปันข้อมูลส่วนบุคคลของผู้ใช้งานกับบริษัท
                      องค์กร และบุคคลภายนอก ไม่ว่ากรณีใด ๆ ทั้งสิ้น<br></br>
                      2.3 ปตท. จะเก็บข้อมูลของท่านเป็นระยะเวลา 1 เดือน
                      โดยจะทำการลบข้อมูลของท่านเมื่อสิ้นสุดกิจกรรม<br></br>
                    </div>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
        </center>

        <div className="d-flex justify-content-center pt-3 pb-5 position-relative">
          <img
            src="../assets/images/ranking/ranking_list.png"
            alt=""
            width={320}
            style={{ height: "100%" }}
          />
          <div className="group-ranking">
            {ranking.map((c) => {
              if (checkID == c.id) {
                return (
                  <div
                    className="row d-flex align-items-center my-2 text-white"
                    style={{ marginLeft: "4%" }}
                  >
                    <div className="col-1">{c.ranking}</div>
                    <div className="col-2">
                      <div className="name">
                        <img src={imgProfile} alt="" width={30} />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="bg-primary rounded  justify-content-around">
                        <span style={{ paddingLeft: "10px" }}>
                          {c.first_name}
                        </span>
                        <span style={{ paddingRight: "10px", float: "right" }}>
                          {c.point.toLocaleString("en-US")}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className="row d-flex align-items-center my-2 text-white"
                    style={{ marginLeft: "4%" }}
                  >
                    <div className="col-1">{c.ranking}</div>
                    <div className="col-2">
                      <div className="name">
                        <img src={imgdefault} alt="" width={30} />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="bg-primary rounded justify-content-around">
                        <span style={{ paddingLeft: "10px" }}>
                          {c.first_name}
                        </span>
                        <span style={{ paddingRight: "10px", float: "right" }}>
                          {c.point.toLocaleString("en-US")}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>

        {/* <div className="d-flex justify-content-center pt-3 pb-5 position-relative">
            <img
              src="../assets/images/ranking/ranking_list.png"
              alt=""
              width={320}
              style={{ height: "100%" }}
            />
            <div className="group-ranking">
              {ranking.map((c) => {
                if (checkID == c.id) {
                  return (
                    <div className="row d-flex align-items-center my-2 text-white">
                      <div className="col-1">{c.ranking}</div>
                      <div className="col-2">
                        <div className="name">
                          <img src={imgProfile} alt="" width={30} />
                        </div>
                      </div>
                      <div className="col-9">
                        <div className="bg-primary rounded  justify-content-around">
                          <span style={{ paddingLeft: "10px" }}>
                            {c.first_name}
                          </span>
                          <span
                            style={{ paddingRight: "10px", float: "right" }}
                          >
                            {c.point}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="row d-flex align-items-center my-2 text-white">
                      <div className="col-1">{c.ranking}</div>
                      <div className="col-2">
                        <div className="name">
                          <img src={imgdefault} alt="" width={30} />
                        </div>
                      </div>
                      <div className="col-9">
                        <div className="bg-primary rounded justify-content-around">
                          <span style={{ paddingLeft: "10px" }}>
                            {c.first_name}
                          </span>
                          <span
                            style={{ paddingRight: "10px", float: "right" }}
                          >
                            {c.point}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div> */}
        <MenuMain />
      </GameLayout>
    </>
  );
};

export default Game;
