import Banner from "../layouts/Banner";
import RegisterLayout from "../layouts/RegisterLayout";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { DownloadOutlined } from "@ant-design/icons";
import { Avatar, Card, Skeleton, Switch } from "antd";
import { getGallery } from "../../functions/content";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";

const { Meta } = Card;

const ShareGallery = () => {
  const { innerHeight: height } = window;

  const [galleries, setGalleries] = useState([]);
  const { id } = useParams();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    getGallery(id)
      .then((res) => {
        // console.log(res);
        const data = res.data.data;

        if (data.gallery !== "" && data.gallery !== "[]") {
          let galleryData = JSON.parse(data.gallery);
          setGalleries(reFormatGallery(galleryData));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const urltoBlob = (url) =>
    new Promise((resolve) => {
      fetch(url).then((response) => resolve(response.blob()));
    });

  const reFormatGallery = (data) => {
    let gallery = data.map(function (obj) {
      obj["title"] = obj["public_id"];
      obj["img"] = obj["url"];
      obj["imgThumbnail"] = obj["url"]; // Assign new key
      //   delete obj["public_id"];
      delete obj["url"]; // Delete old key
      return obj;
    });
    return gallery;
  };

  const download = async (id) => {
    let filterGallery = galleries.filter((item) => {
      return item.public_id === id;
    });

    let data = filterGallery[0];

    if (width <= 768) {
      if (!navigator.canShare) {
        toast.error(`Your browser doesn't support the Web Share API1.`);
        return;
      }

      const media = await urltoBlob(data.img);

      const image = {
        files: [
          new File([media], data.title + ".jpg", {
            type: media.type,
          }),
        ],
        // title: "PTT",
        // text: "PTT",
      };

      if (navigator.canShare(image)) {
        try {
          await navigator.share(image);
          toast.success("Shared!");
        } catch (error) {
          toast.error(`Error: ${error.message}`);
        }
      } else {
        toast.error(`Your system doesn't support sharing these files.`);
      }
    } else {
      let url = data.img;
      let name = data.title;
      saveAs(url, name);
      toast.success("Shared!");
    }
  };

  const contentHeight = Math.ceil((90 / 100) * height);
  var style = {
    overflowX: "hidden",
    overflowY: "auto",
    height: `${contentHeight}px`,
    borderBottomLeftRadius: "50px",
    borderBottomRightRadius: "50px",
  };

  return (
    <>
      <RegisterLayout title="Thankyou">
        <Banner />
        <div className="bg-white mt-1 mx-2" id="content" style={style}>
          {galleries.map((g) => (
            <Card
              actions={[
                <DownloadOutlined
                  onClick={() => download(`${g.public_id}`)}
                  key="ellipsis"
                />,
              ]}
            >
              <Meta
                avatar={<Avatar src={g.img} />}
                title={g.title}
                description={g.title}
              />
            </Card>
          ))}
        </div>
      </RegisterLayout>
    </>
  );
};

export default ShareGallery;
