
import React from 'react';
import { NavLink } from "react-router-dom";
import { Col, Row } from "antd";

const MenuMain = () => {
  var style = {
    textAlign: "center",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "60px",
    width: "100%",
  };

  var phantom = {
    display: "block",
    height: "60px",
    width: "100%",
  };
  return (
    <>
      <div style={phantom} />
      <div style={style}>
        <div className="menu-main vw-100">
          <ul>
            <Row justify="space-between">
              <Col span={4}>
                <li>
                  <NavLink to={"/home"}>
                    <img style={{width:'33px'}} src="../assets/images/page_point/icon_home.png" />
                  </NavLink>
                </li>
              </Col>
              <Col span={4}>
                <li>
                  <NavLink to={"/contents"}>
                    <img style={{width:'33px'}} src="../assets/images/page_point/icon_doc.png" />
                  </NavLink>
                </li>
              </Col>
              <Col span={4}>
                <li>
                  <NavLink to={"/editProfile"}>
                    <img style={{width:'33px'}} src="../assets/images/page_point/icon_user.png" />
                  </NavLink>
                </li>
              </Col>
              <Col span={4}>
                <li>
                  <NavLink to={"/game"}>
                    <img style={{width:'33px'}} src="../assets/images/page_point/icon_game.png" />
                  </NavLink>
                </li>
              </Col>
            </Row>
          </ul>
          {/* <ul>
            <li><NavLink to={"/home"}><img alt='' src="../assets/images/page_point/home_p2.png" /></NavLink></li>
            <li><NavLink to={"/contents"}><img alt='' src="../assets/images/page_point/date_p2.png" /></NavLink></li>
            <li><NavLink to={"#"}><img alt='' src="../assets/images/page_point/ham_p2.png" /></NavLink></li>
            <li><NavLink to={"#"}><img alt='' src="../assets/images/page_point/star_p2.png" /></NavLink></li>
          </ul> */}
        </div>
      </div>
    </>
  );
};

export default MenuMain;
