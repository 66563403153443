import React, { useState, useRef, useContext,useEffect } from "react";
import UploadLayout from "../layouts/UploadLayout";
import { Upload, message, Card } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import UploadImage_ from "../layouts/uploadImg";
import { inputImg, uploadImgV2,getCheckImg } from "../../functions/inputImg";
import { UserContext } from "../../context";
import MenuMain from "./MenuMain";

const UploadImage = () => {
  const [state, setState] = useContext(UserContext);
  // state

  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState("");
  const [checkImg, setCheckImg] = useState("");
  const inputRef = useRef();
  const Css = {
    backgroundSize: "70%",
    height: "300px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url("../assets/images/upload_smoke/button_upload.png")`,
  };
  const [Cssz, setCss] = useState(Css);

  const Banner = {
    width: "100%",
    height: "50%",
    objectFit: "cover",
    border: "3px solid #fff",
  };
  useEffect(() => {
    // loadRanking();
    // console.log("checkImg",checkImg)
    // setCheckImg(obj.user.id);
    loadCheckImg();
  
    
  }, [state && state.token]);

  const loadCheckImg = async () => {
    getCheckImg(state.token).then((res) => {
        // console.log("checkImg",res.data.status)
        setCheckImg(res.data.status);     
  }); 
 
};

  const handleSubmit = async (e) => {
  
    if (img === "") {
      alert("กรุณาอัพโหลดรูปภาพ");
    } else {
      if(checkImg === 1){
        alert("ท่านได้อัปโหลดภาพครบ 5 ครั้งแล้ว");
      window.location.href = "/Game";
      }else{
      e.preventDefault();
      setLoading(true);
      let data = new FormData();
      // data.append("file", img);
      // data.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
      // data.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);

      data.append("file", img);
      data.append("upload_preset", process.env.REACT_APP_PRESET_NAME);
      data.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
      data.append("folder", "ptt-line");

      let authtoken = state.token;

      fetch(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`,
        {
          method: "post",
          body: data,
        }
      )
        .then((resp) => resp.json())
        .then((data) => {

          let addImg = {
            img: data.url,
          };
          inputImg(authtoken, addImg)
            .then((res) => {
              if (res.status === 404) {
                alert("Something went wrong!");
              } else if(res.status === 201){
                setLoading(false);
                alert("บันทึกสำเร็จ");
                window.location.href = "Game";
              }


            })
            .catch((err) => {
              setLoading(false);
              // console.log("err: ", err);
              alert("บันทึกไม่สำเร็จ");
            });
        })
        .catch((err) => console.log(err));
      // setLoading(false);
      // const resp = await uploadImgV2(data)
    }
  }
  };

  const Cssx = {
    backgroundSize: "70%",
    height: "300px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: "",
  };

  const handleImageClick = () => {
    console.log("sdsd", inputRef);
    if (inputRef.current) {
      inputRef.current.click();
      setCss(Cssx);
    }
  };

  return (
    <>
      <UploadLayout title={"upload-smoke"}>
       
        <div className="container mx-auto vh-100" style={{overflow:'scroll'}}>
          <div className="d-flex justify-content-center mt-4">
            <img
              src="../assets/images/upload_smoke/PTTline_upload_bonus-point.png"
              alt=""
              style={Banner}
            />
          </div>
          <br></br>
          <form onSubmit={handleSubmit}>
            <Card
              style={{ width: "100%", position: "relative", marginLeft: "1%" }}
              className="rounded-5 mb-3"
            >
              <div className="text-center">
                {/* <img
                  src="../assets/images/upload_smoke/upload_2.png"
                  alt=""
                  width={200}
                /> */}
                <b style={{fontSize:'16px',color:'#215bde'}}>อัปโหลดรูปภาพสิ่งของที่นำกลับมา REUSE</b>
              </div>
              <center>
                <div onClick={handleImageClick} style={Cssz}>
                  <UploadImage_
                    img={img}
                    setImg={setImg}
                    inputRef={inputRef}
                    required
                  />
                </div>

                {loading ? (
                  <LoadingOutlined className="text-danger" />
                ) : (
                  <button
                    className="text-center my-2"
                    style={{ backgroundColor: "transparent", border: "0" }}
                    variant="primary"
                    type="submit"
                    loadings
                  >
                    <img
                      src="../assets/images/edit_profile/enter.png"
                      width={200}
                      alt=""
                    />
                  </button>
                )}
              </center>
              {/* <div className='text-center my-2'><img src="../assets/images/edit_profile/enter.png" width={200} alt="" /></div> */}
            </Card>
          </form>
         <div style={{height:'100px'}}></div>
        </div>
        
        <MenuMain />
      </UploadLayout>
    </>
  );
};

export default UploadImage;
