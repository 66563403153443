import Mainlayout from "../layouts/Mainlayout";
import MenuMain from "../shared/MenuMain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";
import BannerContent from "../layouts/BannerContent";
import React, { useContext, useState, useEffect } from "react";
import { DatePicker, Select } from "antd";
import dayjs from "dayjs";
import {
  getCategory,
  getContents,
  getContentByMonth,
} from "../../functions/content";
import { UserContext } from "../../context";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const initialState = {
  date: null,
};

const Contents = () => {
  const [state, setState] = useContext(UserContext);
  // state
  const { innerHeight: height } = window;
  const [values, setValues] = useState(initialState);
  const { date } = values;
  const [heightWindow, setHeightWindow] = useState(1);
  const [category, setCategory] = useState([]);
  const [contents, setContents] = useState([]);
  const [categorySelect, setCategorySelect] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [dateSelect, setDateSelect] = useState("");

  useEffect(() => {
    setHeightWindow(height - 200);
    if (state && state.token) {
      loadCategory();
      loadContents();
    }
  }, [state && state.token, height, heightWindow]);

  const loadCategory = () => {
    getCategory(state.token)
      .then((res) => {
        const data = res.data.data;
        setCategory(reFormatCategory(data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadContents = () => {
    getContents(state.token)
      .then((res) => {
        const data = res.data.data;

        setContents(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadContentsByMonth = (month) => {
    getContentByMonth(state.token, month)
      .then((res) => {
        const data = res.data.data;

        setContents(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reFormatCategory = (data) => {
    data.push({ id: 0, name: "ทั้งหมด" });
    let capitals = data.map(function (obj) {
      obj["value"] = obj["id"];
      obj["label"] = obj["name"]; // Assign new key
      delete obj["name"]; // Delete old key
      return obj;
    });
    return capitals.sort().reverse();
  };

  const onChange = (date, dateString) => {
    // setDate(date)

    setDateSelect(dateString);
    setValues({ ...values, date: date });

    if (date) {
      loadContentsByMonth(dateString);
    } else {
      loadContents();
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current < dayjs(1).year(2019) || current > dayjs().year(2023);
  };

  const handleChange = (value) => {
    setCategorySelect(value);
  };

  const searched = (categorySelect, keyword, dateSelect) => (c) => {
    // let dt = new Date( c.created_date );

    // const month = `${dt.getMonth() + 1}`.padStart(2, "0")
    // const monthYear = `${dt.getFullYear()}-${month}`

    // const checkDate = monthYear === dateSelect ? true : false

    // return checkDate
    return categorySelect !== 0
      ? c.category_id === categorySelect &&
          c.title.toLowerCase().includes(keyword)
      : c && c.title.toLowerCase().includes(keyword);
  };

  return (
    <Mainlayout title={"Article"}>
      <BannerContent keyword={keyword} setKeyword={setKeyword} />
      <div className="px-3 text-center mt-1">
        <img
          src="../assets/images/page_article/article-godji.png"
          alt=""
          className="w-100"
        />
      </div>
      <div
        className="px-3"
        style={{
          overflowY: "scroll",
          paddingTop: "56px",
          paddingBottom: "100px",
        }}
      >
        <div
          className="suggest bg-white"
          id="article"
          style={{ marginTop: "-55px", minHeight: `${heightWindow}px` }}
        >
          <div className="text-center text-art text-primary">
            เลือกหมวดหมู่ตามต้องการ
          </div>

          <div className="menu-category-article">
            <ul
              className=""
              // style={{ justifyContent: "end", marginRight: "10px" }}
              style={{ justifyContent: "center" }}
            >
              <li className="text-white" style={{ marginRight: "10px" }}>
                <Select
                  defaultValue="ทั้งหมด"
                  bordered={false}
                  style={{
                    height: "20px",
                    borderWidth: "2px",
                    width: "120px",
                    fontSize: "9px",
                  }}
                  onChange={handleChange}
                  options={category}
                />
              </li>

              <li className="text-primary">
                <DatePicker
                  picker="month"
                  onChange={onChange}
                  disabledDate={disabledDate}
                  placeholder="เดือน : ปัจจุบัน"
                  name="date"
                  value={date}
                  style={{
                    height: "20px",
                    borderWidth: "2px",
                    fontSize: "14px",
                  }}
                  className="rounded-pill border-0 text-center border-danger"
                />
              </li>
            </ul>
          </div>

          <div className="row mt-2 wrapper-product">
            <div
              className="row product-list boxContantall"
              style={{ overflow: "scroll", height: "500px" }}
            >
              {contents
                .filter(searched(categorySelect, keyword, dateSelect))
                .map((c) => (
                  <div
                    key={c.id}
                    className="col-6 mb-4 p-0 d-flex justify-content-center SubboximgHome"
                  >
                    <NavLink to={`/content/${c.id}`}>
                      <div className="img mx-auto">
                        <div className="img-pro mx-auto">
                          <img
                            src={c.img}
                            style={{ borderRadius: "12px" }}
                            alt=""
                          />
                        </div>
                        <div className="row px-2">
                          <div className="col-9 ps-2 lh-1 text-uppercase text-primary d-flex align-items-center product-name">
                            {c.title}
                          </div>
                          <div className="col-3">
                            <div className="fontawesome text-end">
                              <div to={""} className={"nav-link"}>
                                <FontAwesomeIcon
                                  icon={faEye}
                                  className="mx-1 text-info"
                                />
                              </div>
                              <span className="text-primary">{c.view}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <MenuMain />
    </Mainlayout>
  );
};

export default Contents;
