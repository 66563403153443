import RegisterLayout from "../layouts/RegisterLayout";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import Banner from "../layouts/Banner";
import { getRegister } from "../../functions/profile";

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  tel: "",
  birth_date: null,
};

const Register = () => {
  const lineAccessToken = sessionStorage.getItem("lineAccessToken");
  const [values, setValues] = useState(initialState);
  const { first_name, last_name, email, tel, birth_date } = values;
  const [datePicker, setDatePicker] = useState(null);

  const navigate = useNavigate();

  const handlerSubmit = async (e) => {
    e.preventDefault();

    console.table(values);

    if (!datePicker) {
      toast.error("Birthdate required");
      return;
    }


    getRegister(lineAccessToken, values).then((res) => {
      // const resp = res.data
      if (res.status === 200) {
        navigate("/login");
      } else if(res.status === 404) {
        toast.error("Something went wrong!");
      }

    });
    // resetFormFields();
    // navigate("/home");
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChange = (date, dateString) => {
    // setDate(date)
    setValues({ ...values, birth_date: dateString });
    setDatePicker(date);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current > dayjs().endOf("day");
  };

  return (
    <>
      <RegisterLayout title="Home">
        <Banner />
        <div style={{ textAlign: "center" }} className="mt-3">
          <img
            src="../assets/images/page_point/logo.png"
            alt=""
            className="w-75"
          />
        </div>
        <div className="container text-center login">
          <h2 className="title-name">ลงทะเบียน</h2>
          <form onSubmit={handlerSubmit}>
            <div className="mb-2 mx-5">
              <input
                type="text"
                className="form-control text-center rounded-pill border-0"
                id="username"
                placeholder="ชื่อ"
                name="first_name"
                value={first_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-2 mx-5">
              <input
                type="text"
                className="form-control text-center rounded-pill border-0"
                id="username"
                placeholder="นามสกุล"
                name="last_name"
                value={last_name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-2 mx-5">
              <input
                type="email"
                className="form-control text-center rounded-pill border-0"
                id="email"
                name="email"
                placeholder="อีเมล"
                value={email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-2 mx-5">
              <input
                type="tel"
                className="form-control text-center rounded-pill border-0"
                id="tel"
                name="tel"
                placeholder="เบอร์โทรศัพท์"
                value={tel}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-2 mx-5">
              <DatePicker
                onChange={onChange}
                disabledDate={disabledDate}
                placeholder="วัน / เดือน / ปีเกิด"
                name="date"
                value={datePicker}
                className="form-control rounded-pill border-0 text-center border-danger datepicker"
                required
              />
            </div>
            <div className="mb-2 mt-3 mx-5">
              <button
                type="submit"
                className=" text-center border-0 bg-primary text-white rounded-pill fs-2 fw-bold mx-auto px-5 py-2"
              >
                {" "}
                เข้าสู่ระบบ{" "}
              </button>
            </div>
          </form>
        </div>
      </RegisterLayout>
    </>
  );
};

export default Register;
