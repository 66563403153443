import React from 'react';
import { Col, Row } from "antd";

const Publie_BannerDetail = ({ isIconRight }) => {
  return (
    <>
      <Row justify="space-between px-3">
        <Col span={8} style={{ textAlign: "left" }}>
          <img
            src="../assets/images/page_point/logo_PTT_3.png"
            alt="PTT"
            style={{width:'60%',marginTop:'5%'}}
          />
        </Col>
        <Col span={8} offset={8} style={{ textAlign: "right" }}>
          
            <img
              src="../assets/images/page_point/logo.png"
              alt="campaign"
              style={{width:'60%'}}
            />
      
        </Col>
      </Row>
    </>
  );
};

export default Publie_BannerDetail;
