import React, { useState, useEffect, useContext } from "react";
import ProfileLayout from "../layouts/ProfileLayout";
import { Link } from "react-router-dom";
import Banner from "../layouts/Banner";
import { toast } from "react-toastify";
import { DatePicker, Tabs, Select, Space } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import "react-datepicker/dist/react-datepicker.css";
// import EditProfile_2 from './EditProfile_2';
import {
  getProvince,
  getDistricts,
  getSubdistricts,
  getZipcode,
  getEditProfile_,
  editUser,
  editAddress,
  getCheckAddress,
  addAddress,
  getAddressFormID_,
} from "../../functions/adddress";
import { UserContext } from "../../context/index";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const EditProfile = () => {
  const [state, setState] = useContext(UserContext);
  useEffect(() => {
    loadProvince();
    loadEditProfile();
    loadCheckAddress();

    // loadAddressID();
  }, [state && state.token, birthdate]);

  const pictureUrl = localStorage.getItem("pictureUrl");

  const [province_id, setProvince] = useState(0); //สร้างตัวแปรไว้ส่งค่าจังหวัดที่เลือก
  const [districts, setDistricts] = useState([]); // รับค่าอำเภอทั้งหมด
  const [provinces, setProvinces] = useState([]); // รับค่าจังหวัดทั้งหมด
  const [district_id, setDistrict] = useState(0); //สร้างตัวแปรไว้ส่งค่าจังหวัดที่เลือก
  const [subDistricts, setSubDistricts] = useState([]);
  const [subDistrict_id, setSubDistrict] = useState(0);
  const [Zipcode, setZipcode] = useState("");
  const [region_id, setRegion_id] = useState(0);
  const [address, setAddress] = useState("");

  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [birthdate, setBirthdate] = useState("");
  // const maxDate = new Date().toISOString().split("T")[0];
  const dateFormat = "YYYY-MM-DD";
  const navigate = useNavigate();

  const [checkAddress, setCheckAddress] = useState(false);
  const [getIdAddress, setGetIdAddress] = useState(0);
  // const [addressFormID,getAddressFormID] = useState("");

  const loadCheckAddress = async () => {
    getCheckAddress(state.token).then((res) => {
      if (res.data.data.length === 0) {
        setGetIdAddress("");
      } else {
        setGetIdAddress(res.data.data[0].id);

        if (res.data.data.length > 0) {
          setCheckAddress(true);
        }
        loadAddressFromID(res.data.data[0].id);
      }
    });
  };

  const loadAddressFromID = async (getIdAddress) => {
    getAddressFormID_(state.token, getIdAddress).then((res) => {
      setAddress(res.data.data.address);
      setProvince(res.data.data.province);
      setDistrict(res.data.data.district);
      setSubDistrict(res.data.data.subdistrict);
      setZipcode(res.data.data.zipcode_name);

      loadDistricts(res.data.data.province);
      // console.log("///",res.data.data.district);
      loadSubDistricts(res.data.data.district);
    });
  };

  const loadProvince = async () => {
    getProvince(state.token).then((res) => {
      setProvinces(res.data.data);
    });
  };
  const handleProvince = (e) => {
    setProvince(e);
    loadDistricts(e);
    loadSubDistricts(e);
    setDistrict(0);
    setSubDistrict(0);
    setZipcode("");

    const filtered = provinces.filter((region_id) => {
      return region_id.id === Number(e);
    });

    setRegion_id(filtered[0].region_id);
  };

  //เรียกตำบล
  const loadDistricts = async (province_id) => {
    getDistricts(state.token, province_id).then((res) => {
      setDistricts(res.data.data);
    });
  };

  //ฟังชั่นเลือกอำเภอ
  const handleDistrict = (e) => {
    setDistrict(e);
    loadSubDistricts(e);
    setSubDistrict(0);
    setZipcode("");
  };

  //เรียกตำบล
  const loadSubDistricts = async (district_id) => {
    getSubdistricts(state.token, district_id).then((res) => {
      setSubDistricts(res.data.data);
    });
  };
  //ฟังชั่นเลือกตำบล
  const handleSubDistrict = (e) => {
    setSubDistrict(e);
    loadZipcode(e);
  };
  //เรียกไปรษณีย์
  const loadZipcode = async (subDistrict_id) => {
    getZipcode(state.token, subDistrict_id).then((res) => {
      setZipcode(res.data.data[0].zipcode);
    });
  };

  const loadEditProfile = async () => {
    getEditProfile_(state.token).then((res) => {
      // setGetEditProfile(res.data.data);
      setName(res.data.data.first_name);
      setSurName(res.data.data.last_name);
      setEmail(res.data.data.email);
      setTel(res.data.data.tel);
      setBirthdate(res.data.data.birthdate);
    });
  };

  const handleProfileSubmit = (e) => {
    e.preventDefault();

    let editUser_ = {
      first_name: name,
      last_name: surName,
      email: email,
      tel: tel,
      birth_date: birthdate,
    };
    handleUpdateProfile(editUser_);
  };

  const handleUpdateProfile = (data) => {
    console.table(data);
    editUser(state.token, data)
      .then((res) => {
        if (res.status === 400) {
          alert("บันทึกไม่สำเร็จ");
        } else {
          console.log("ee", res);
          alert("บันทึกสำเร็จ");
          // window.location.href = "/edit_profile";
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        alert("บันทึกไม่สำเร็จ");
      });
  };

  const updateFields = (date, dateString) => {
    if (dateString) {
      setBirthdate(dateString);
    }
    // console.log((date));
    // console.log(dateString)
  };

  const handleAddressSubmit = (e) => {
    if (subDistrict_id == 0) {
      toast.error("กรุณากรอกข้อมูลให้ครบ");
      e.preventDefault();
    } else {
      if (!checkAddress) {
        e.preventDefault();
        let authtoken = state.token;
        let addAddress_ = {
          address: address,
          province_id: province_id,
          district_id: district_id,
          subdistrict_id: subDistrict_id,
          region_id: region_id,
        };
        addAddress(authtoken, addAddress_)
          .then((res) => {
            if (res.data.status_code != 201) {
              alert("เพิ่มที่อยู่ไม่สำเร็จ");
            } else {
              alert("เพิ่มที่อยู่สำเร็จ");
              navigate("/home");
            }
          })
          .catch((err) => {
            alert("เพิ่มที่อยู่ไม่สำเร็จ");
            // window.location.reload(false);alertSuscess
          });
      } else {
        e.preventDefault();

        let authtoken = state.token;
        let editAddressID = {
          id: getIdAddress,
          address: address,
          province_id: province_id,
          district_id: district_id,
          subdistrict_id: subDistrict_id,
          region_id: region_id,
        };

        editAddress(authtoken, editAddressID)
          .then((res) => {
            if (res.data.status_code != 201) {
              alert("บันทึกที่อยู่ไม่สำเร็จ");
            } else {
              alert("บันทึกที่อยู่สำเร็จ");
              navigate("/home");
            }
          })
          .catch((err) => {
            console.log("err: ", err);
            alert("บันทึกที่อยู่ไม่สำเร็จ");
          });
      }
    }
  };

  function GetSubDis() {
    if (district_id != 0) {
      return (
        <Select
          className="form-control bg-white my-2 px-2 py-1 rounded-5 border-0 mx-auto shadow text-center"
          defaultValue="แขวง / ตำบล"
          style={{ width: "80%" }}
          bordered={false}
          value={subDistrict_id}
          onChange={handleSubDistrict}
        >
          <option value={0} disabled>
            แขวง/ตำบล
          </option>
          {subDistricts.map((item) => (
            <option value={item.id}>{item.name_th}</option>
          ))}
        </Select>
      );
    } else {
      return (
        <Select
          className="form-control bg-white my-2 px-2 py-1 rounded-5 border-0 mx-auto shadow text-center"
          defaultValue="แขวง / ตำบล"
          style={{ width: "80%" }}
          bordered={false}
          value={subDistrict_id}
          onChange={handleSubDistrict}
          disabled
        >
          <option value={0} disabled>
            แขวง/ตำบล
          </option>
          {subDistricts.map((item) => (
            <option value={item.id}>{item.name_th}</option>
          ))}
        </Select>
      );
    }
  }

  function GetDis() {
    if (province_id != 0) {
      return (
        <Select
          className="form-control bg-white my-2 px-2 py-1 rounded-5 border-0 mx-auto shadow text-center"
          defaultValue="เขต / อำเภอ"
          style={{ width: "80%" }}
          bordered={false}
          onChange={handleDistrict}
          value={district_id}
        >
          <option value={0} disabled>
            เขต/อำเภอ
          </option>
          {districts.map((item) => (
            <option value={item.id}>{item.name_th}</option>
          ))}
        </Select>
      );
    } else {
      return (
        <Select
          className="form-control bg-white my-2 px-2 py-1 rounded-5 border-0 mx-auto shadow text-center"
          defaultValue="เขต / อำเภอ"
          style={{ width: "80%" }}
          bordered={false}
          onChange={handleDistrict}
          value={district_id}
          disabled
        >
          <option value={0} disabled>
            เขต/อำเภอ
          </option>
          {districts.map((item) => (
            <option value={item.id}>{item.name_th}</option>
          ))}
        </Select>
      );
    }
  }

  return (
    <>
      <ProfileLayout title={"Edit Profile"}>
        <Banner />

        <div className="container mx-auto vh-100">
          <div className="row text-center linkTitleShare">
            <div className="col-2">
              <Link to="/home">
                <img
                  src="../assets/images/edit_profile/back.png"
                  alt=""
                  width={20}
                />
              </Link>
            </div>
            <div className="col-8">
              <img
                src="../assets/images/edit_profile/textTitle.png"
                alt=""
                width={150}
              />
            </div>
          </div>

          <div className="text-center wrap-profile">
            <div className="mx-auto wrapper">
              <img src={pictureUrl} alt="" className="profile-pic" />
            </div>
            {/* <p className='py-1 m-0'>เปลี่ยนรูป</p> */}
          </div>

          <Tabs>
            <TabPane tab="ข้อมูลโปรไฟล์" key={1}>
              <form
                className="d-flex flex-column w-100 mb-2"
                onSubmit={handleProfileSubmit}
              >
                <input
                  type="text"
                  name=""
                  id=""
                  style={{ width: "80%" }}
                  className="form-control  bg-white my-2 px-2 py-2 rounded-5 border-0 mx-auto shadow text-center"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  placeholder="ชื่อ"
                />
                <input
                  type="text"
                  name=""
                  id=""
                  style={{ width: "80%" }}
                  className="form-control  bg-white my-2 px-2 py-2 rounded-5 border-0 mx-auto shadow text-center"
                  value={surName}
                  required
                  onChange={(e) => setSurName(e.target.value)}
                  placeholder="นามสกุล"
                />
                <input
                  type="email"
                  name=""
                  id=""
                  style={{ width: "80%" }}
                  className="form-control  bg-white my-2 px-2 py-2 rounded-5 border-0 mx-auto shadow text-center"
                  value={email}
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="อีเมล์"
                />
                <input
                  type="tel"
                  name=""
                  id=""
                  style={{ width: "80%" }}
                  className="form-control  bg-white my-2 px-2 py-2 rounded-5 border-0 mx-auto shadow text-center"
                  value={tel}
                  required
                  onChange={(e) => setTel(e.target.value)}
                  placeholder="เบอร์โทรศัพท์"
                />

                <DatePicker
                  style={{ width: "80%" }}
                  placeholder="วัน / เดือน / ปีเกิด"
                  className="form-control my-2 px-2 py-2 rounded-5 border-0 mx-auto shadow text-center datepicker"
                  value={dayjs(birthdate, dateFormat)}
                  // onChange={(e) => setBirthdate(e.target.value)}
                  onChange={updateFields}
                  // max={maxDate}
                  // onChange={(e) => setBirthdate(e.target.value)}
                  // type="date"
                  defaultValue={dayjs()}
                  required
                />

                <div className="mx-auto">
                  <button
                    style={{ backgroundColor: "transparent", border: "0" }}
                    type="submit"
                  >
                    <img
                      src="../assets/images/edit_profile/next.png"
                      alt=""
                      width={90}
                    />
                  </button>
                </div>
              </form>
            </TabPane>

            <TabPane tab="ข้อมูลที่อยู่" key={2}>
              <div>
                <form
                  className="d-flex flex-column w-100 mb-2"
                  onSubmit={handleAddressSubmit}
                >
                  <input
                    type="text"
                    name=""
                    id=""
                    style={{ width: "80%" }}
                    className="form-control bg-white my-1 px-2 py-2 rounded-5 border-0 mx-auto shadow text-center"
                    placeholder="ที่อยู่"
                    value={address}
                    required
                    onChange={(e) => setAddress(e.target.value)}
                  />

                  <Space
                    wrap
                    direction="vertical"
                    className="d-flex justify-content-center w-100 rounded-pill"
                  >
                    <Select
                      className="form-control bg-white my-2 px-2 py-1 rounded-5 border-0 mx-auto shadow text-center"
                      style={{ width: "80%" }}
                      bordered={false}
                      onChange={handleProvince}
                      value={province_id}
                      required
                    >
                      <option value={0} disabled>
                        จังหวัด
                      </option>
                      {provinces.map((item) => (
                        <option value={item.id}>{item.name_th}</option>
                      ))}
                    </Select>
                  </Space>

                  <Space
                    wrap
                    direction="vertical"
                    className="d-flex justify-content-center w-100 rounded-pill"
                  >
                    <GetDis />
                  </Space>

                  <Space
                    wrap
                    direction="vertical"
                    className="d-flex justify-content-center rounded-pill"
                    style={{ width: "100%" }}
                  >
                    <GetSubDis />
                  </Space>

                  <input
                    type="text"
                    name=""
                    id=""
                    style={{ width: "80%" }}
                    className="form-control bg-white my-1 px-2 py-2 rounded-5 border-0 mx-auto shadow text-center"
                    placeholder="รหัสไปรษณีย์"
                    value={Zipcode}
                    disabled
                    required
                    onChange={(e) => setZipcode(e)}
                  />

                  <div className="mx-auto mt-3">
                    <button
                      style={{ backgroundColor: "transparent", border: "0" }}
                      type="submit"
                    >
                      <img
                        src="../assets/images/edit_profile/enter.png"
                        alt=""
                        width={180}
                      />
                    </button>
                  </div>
                </form>
              </div>
            </TabPane>
          </Tabs>
        </div>
      </ProfileLayout>
    </>
  );
};

export default EditProfile;
