import React from 'react'
import DocumentTitle from "react-document-title";

const GameLayout = ({children, title}) => {

    var style = {
        backgroundImage: `url("../assets/images/ranking/bg.png")`,
        height: "100%"
    }

  return (
    <>
        <DocumentTitle title={title} />
        <div style={style} className='position-relative bg-home'>
            {children}
        </div>
    </>
  )
}

export default GameLayout